import CompanyApi from '../api/CompanyApi';
import ActionTypes from '../constants/actionTypes';
import { NotificationManager } from 'react-notifications';

export function getAllCompanies() {
  return (dispatch) => {
    dispatch(request());
    return CompanyApi.getAllCompanies()
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.GET_ALL_COMPANIES.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.GET_ALL_COMPANIES.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.GET_ALL_COMPANIES.FAILURE, payload: error };
  }
}

export function getCompanyDetail(id) {
  return (dispatch) => {
    dispatch(request());
    return CompanyApi.getCompanyDetail(id)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.GET_COMPANY_DETAIL.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.GET_COMPANY_DETAIL.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.GET_COMPANY_DETAIL.FAILURE, payload: error };
  }
}

export function addCompany(name, company_type, brokerage_firm_dre_license_number) {
  return (dispatch) => {
    dispatch(request());
    return CompanyApi.addCompany(name, company_type, brokerage_firm_dre_license_number)
      .then((resp) => {
        console.log('resp', resp);
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.ADD_COMPANY.REQUEST };
  }
  function success(data) {
    NotificationManager.success('The company has been added successfully', 'Success!');
    return { type: ActionTypes.ADD_COMPANY.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.ADD_COMPANY.FAILURE, payload: error };
  }
}

export function updateCompanyStatus(company, status) {
  return (dispatch) => {
    dispatch(request());
    return CompanyApi.updateCompanyStatus(company.id, status)
      .then((resp) => {
        return dispatch(success({ ...company, status: status == '2' ? 'Approved' : 'Rejected' }));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.UPDATE_COMPANY_STATUS.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.UPDATE_COMPANY_STATUS.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.UPDATE_COMPANY_STATUS.FAILURE, payload: error };
  }
}

export function deleteCompany(company) {
  return (dispatch) => {
    dispatch(request());
    return CompanyApi.deleteCompany(company.id)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.DELETE_COMPANY.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.DELETE_COMPANY.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.DELETE_COMPANY.FAILURE, payload: error };
  }
}
