import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import rejectImg from '../../assets/images/reject.svg';
import { updateCompanyStatus, deleteCompany, getAllCompanies } from '../../actions/company';
import classNames from 'classnames';

const CompanyTable = ({ data }) => {
  const dispatch = useDispatch();

  const handleUpdateCompanyStatus = async (company, status) => {
    await dispatch(updateCompanyStatus(company, status.toString()));
  };

  const handleCompanyDelete = async (company) => {
    var result = confirm(`Are you sure you want to delete ${company.name || 'this company'}?`);
    if (result) {
      const resp = await dispatch(deleteCompany(company));

      if(resp.type === 'DELETE_COMPANY_SUCCESS') {
        dispatch(getAllCompanies());
      }
    }
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Company Name</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.id}>
            <td>
              <Link to={'/admin/company/' + item.id}>{item.name}</Link>
            </td>
            <td>
              <div
                className={classNames({
                  status: true,
                  approved: item.status === 'Approved',
                  rejected: item.status === 'Rejected',
                })}
              >
                {item.status}
              </div>
            </td>
            <td className="actions">
                <div className="button-group">
                  {item.status === 'Waiting' 
                  && <>
                    <span className="approve" onClick={() => handleUpdateCompanyStatus(item, 2)}>
                      Approve
                    </span>
                    <div className="reject" onClick={() => handleUpdateCompanyStatus(item, 3)}>
                      <img src={rejectImg} alt="" />
                      <span>Reject</span>
                    </div>
                  </>}
                  <span className="delete" onClick={() => handleCompanyDelete(item)}>
                    Delete
                  </span>
                </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CompanyTable;
