import Navbar from './Navbar';
import '../assets/styles/layout.scss';

const Layout = ({ children }) => {
  return (
    <div className="default-layout">
      <Navbar />
      <div className="content">{children}</div>
    </div>
  );
};

export default Layout;
