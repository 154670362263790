import Layout from '../components/Layout';
import '../assets/styles/successful-unsubscription.scss';

import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Unsubscribe } from '../actions/prospective_clients';
import { useParams, useHistory } from 'react-router-dom';

const SuccessfulUnsubscription = () => {
  let { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Unsubscribe(id));
  }, [dispatch]);

  return (
    <Layout>
      <div className="successful-unsubscription">
        <h1 className="message">You have successfully unsubscribed from Future Listing emails.</h1>
      </div>
    </Layout>
  );
};

export default SuccessfulUnsubscription;
