import AdminApi from '../api/AdminApi';
import ActionTypes from '../constants/actionTypes';

export function getAllUsers(userType, status) {
  return (dispatch) => {
    dispatch(request());
    return AdminApi.getAllUsers(userType, status)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.GET_ALL_USERS.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.GET_ALL_USERS.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.GET_ALL_USERS.FAILURE, payload: error };
  }
}

export function getUserDetail(id) {
  return (dispatch) => {
    dispatch(request());
    return AdminApi.getUserDetail(id)
      .then((resp) => {
        console.log('resp', resp);
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.GET_USER_DETAIL.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.GET_USER_DETAIL.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.GET_USER_DETAIL.FAILURE, payload: error };
  }
}

export function updateUserStatus(email, status) {
  return (dispatch) => {
    dispatch(request());
    return AdminApi.updateUserStatus(email, status)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.UPDATE_USER_STATUS.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.UPDATE_USER_STATUS.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.UPDATE_USER_STATUS.FAILURE, payload: error };
  }
}

export function deleteUser(user) {
  return (dispatch) => {
    dispatch(request());
    return AdminApi.deleteUser(user.id)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.DELETE_USER.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.DELETE_USER.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.DELETE_USER.FAILURE, payload: error };
  }
}