import { defineAction } from 'redux-define';

export default {
  AUTH_BUYER_SIGNUP: defineAction('AUTH_BUYER_SIGNUP', ['REQUEST', 'SUCCESS', 'FAILURE']),
  AUTH_REALTOR_SIGNUP: defineAction('AUTH_REALTOR_SIGNUP', ['REQUEST', 'SUCCESS', 'FAILURE']),
  AUTH_LOAN_OFFICER_SIGNUP: defineAction('AUTH_LOAN_OFFICER_SIGNUP', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  AUTH_CREATE_PASSWORD: defineAction('AUTH_CREATE_PASSWORD', ['REQUEST', 'SUCCESS', 'FAILURE']),
  AUTH_FORGOT_PASSWORD: defineAction('AUTH_FORGOT_PASSWORD', ['REQUEST', 'SUCCESS', 'FAILURE']),
  AUTH_LOGIN: defineAction('AUTH_LOGIN', ['REQUEST', 'SUCCESS', 'FAILURE']),
  AUTH_LOGOUT: defineAction('AUTH_LOGOUT', ['REQUEST', 'SUCCESS', 'FAILURE']),
  CLEAR_AUTH_STATE: 'CLEAR_AUTH_STATE',

  /** Company constants */
  GET_ALL_COMPANIES: defineAction('GET_ALL_COMPANIES', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_COMPANY_DETAIL: defineAction('GET_COMPANY_DETAIL', ['REQUEST', 'SUCCESS', 'FAILURE']),
  ADD_COMPANY: defineAction('ADD_COMPANY', ['REQUEST', 'SUCCESS', 'FAILURE']),
  UPDATE_COMPANY_STATUS: defineAction('UPDATE_COMPANY_STATUS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  DELETE_COMPANY: defineAction('DELETE_COMPANY', ['REQUEST', 'SUCCESS', 'FAILURE']),

  /** User constants */
  GET_ALL_USERS: defineAction('GET_ALL_USERS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_USER_DETAIL: defineAction('GET_USER_DETAIL', ['REQUEST', 'SUCCESS', 'FAILURE']),
  UPDATE_USER_STATUS: defineAction('UPDATE_USER_STATUS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_USER_PROFILE: defineAction('GET_USER_PROFILE', ['REQUEST', 'SUCCESS', 'FAILURE']),
  UPDATE_USER_PROFILE: defineAction('UPDATE_USER_PROFILE', ['REQUEST', 'SUCCESS', 'FAILURE']),
  DELETE_USER: defineAction('DELETE_USER', ['REQUEST', 'SUCCESS', 'FAILURE']),

  GET_FUTURE_LISTING: defineAction('GET_FUTURE_LISTING', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_BUYER_FUTURE_LISTING: defineAction('GET_BUYER_FUTURE_LISTING', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  CREATE_FUTURE_LISITING: defineAction('CREATE_FUTURE_LISITING', ['REQUEST', 'SUCCESS', 'FAILURE']),

  GET_PROSPECTIVE_CLIENTS: defineAction('GET_PROSPECTIVE_CLIENTS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_UNSUBSCRIBE: defineAction('GET_UNSUBSCRIBE', ['REQUEST', 'SUCCESS', 'FAILURE']),
};
