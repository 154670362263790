import AuthApi from '../api/AuthApi';
import ActionTypes from '../constants/actionTypes';
import { NotificationManager } from 'react-notifications';

export function buyerSignup(data) {
  return (dispatch) => {
    dispatch(request());
    return AuthApi.buyerSignup(data)
      .then((resp) => {
        return dispatch(success(resp));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.AUTH_BUYER_SIGNUP.REQUEST };
  }
  function success(data) {
    NotificationManager.success('Signup successful', 'Success!');
    return { type: ActionTypes.AUTH_BUYER_SIGNUP.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.AUTH_BUYER_SIGNUP.FAILURE, payload: error };
  }
}

export function realtorSignup(data) {
  return (dispatch) => {
    dispatch(request());
    return AuthApi.realtorSignup(data)
      .then((resp) => {
        return dispatch(success(resp));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.AUTH_REALTOR_SIGNUP.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.AUTH_REALTOR_SIGNUP.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.AUTH_REALTOR_SIGNUP.FAILURE, payload: error };
  }
}

export function loanOfficerSignup(data) {
  return (dispatch) => {
    dispatch(request());
    return AuthApi.loanOfficerSignup(data)
      .then((resp) => {
        return dispatch(success(resp));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.AUTH_LOAN_OFFICER_SIGNUP.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.AUTH_LOAN_OFFICER_SIGNUP.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.AUTH_LOAN_OFFICER_SIGNUP.FAILURE, payload: error };
  }
}

export function createPassword(url, data) {
  return (dispatch) => {
    dispatch(request());
    return AuthApi.createPassword(url, data)
      .then((resp) => {
        return dispatch(success(resp));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.AUTH_CREATE_PASSWORD.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.AUTH_CREATE_PASSWORD.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.AUTH_CREATE_PASSWORD.FAILURE, payload: error };
  }
}

export function login(data) {
  return (dispatch) => {
    dispatch(request());
    return AuthApi.login(data)
      .then((resp) => {
        localStorage.setItem('token', resp.data.token);
        localStorage.setItem('user', JSON.stringify(resp.data.user));
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.AUTH_LOGIN.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.AUTH_LOGIN.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.AUTH_LOGIN.FAILURE, payload: error };
  }
}

export function forgotPassword(data) {
  return (dispatch) => {
    dispatch(request());
    return AuthApi.forgotPassword(data)
      .then((resp) => {
        console.log('resp', resp);
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        return dispatch(failure(error.response));
      });
  };

  function request() {
    return { type: ActionTypes.AUTH_FORGOT_PASSWORD.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.AUTH_FORGOT_PASSWORD.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.AUTH_FORGOT_PASSWORD.FAILURE, payload: error };
  }
}

export function logout() {
  return (dispatch) => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    dispatch(success());
  };

  function success() {
    return { type: ActionTypes.AUTH_LOGOUT.SUCCESS };
  }
}

export function clearState() {
  return (dispatch) => {
    dispatch(success());
  };

  function success() {
    return { type: ActionTypes.CLEAR_AUTH_STATE };
  }
}

export function getUserProfile() {
  return (dispatch) => {
    dispatch(request());
    return AuthApi.getUserProfile()
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.GET_USER_PROFILE.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.GET_USER_PROFILE.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.GET_USER_PROFILE.FAILURE, payload: error };
  }
}

export function updateUserProfile(data) {
  return (dispatch) => {
    dispatch(request());
    return AuthApi.updateUserProfile(data)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.UPDATE_USER_PROFILE.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.UPDATE_USER_PROFILE.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.UPDATE_USER_PROFILE.FAILURE, payload: error };
  }
}
