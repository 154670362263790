import moment from 'moment';
import ActionTypes from '../constants/actionTypes';

const initialState = {
  users: [],
  user: {},
  loading: false,
  error: '',
};

export default function admin(state = initialState, action) {
  let { payload } = action;

  switch (action.type) {
    case ActionTypes.GET_ALL_USERS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_ALL_USERS.SUCCESS:
      return {
        ...state,
        users: payload.sort((a, b) => {
          return moment(b.date_joined) - moment(a.date_joined);
        }),
        loading: false,
      };
    case ActionTypes.GET_ALL_USERS.FAILURE:
      return {
        ...state,
        error: payload.error.message,
        loading: false,
      };
    case ActionTypes.GET_USER_DETAIL.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_USER_DETAIL.SUCCESS:
      console.log(payload);
      return {
        ...state,
        user: payload,
        loading: false,
      };
    case ActionTypes.GET_USER_DETAIL.FAILURE:
      return {
        ...state,
        error: payload.error.message,
        loading: false,
      };
    case ActionTypes.UPDATE_USER_STATUS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.UPDATE_USER_STATUS.SUCCESS:
      return {
        ...state,
        users: state.users.map((c) => {
          return c.id === payload.id ? payload : c;
        }),
        user: payload,
        loading: false,
      };
    case ActionTypes.UPDATE_USER_STATUS.FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
