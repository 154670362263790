import axios from 'axios';
import BaseApi from './BaseApi';

class AdminApi extends BaseApi {
  getAllUsers(userType, status) {
    if (!status)
      return axios.get(this.REACT_APP_SERVER_URL + `/users?user_type=${userType}`, {
        headers: { Authorization: `Bearer ${this.getToken()}` },
      });
    return axios.get(this.REACT_APP_SERVER_URL + `/users?user_type=${userType}&status=${status}`, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  getUserDetail(id) {
    return axios.get(this.REACT_APP_SERVER_URL + '/user-detail/' + id, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  deleteUser(id) {
    return axios.delete(this.REACT_APP_SERVER_URL + '/user/' + id, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }


  updateUserStatus(email, status) {
    return axios.post(
      this.REACT_APP_SERVER_URL + '/update-user-status',
      { email, status },
      {
        headers: { Authorization: `Bearer ${this.getToken()}` },
      },
    );
  }
}
export default new AdminApi();
