import { useState, useEffect} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import Layout from '../components/Layout';
import '../assets/styles/future-listing.scss';
import plusImg from '../assets/images/plus-circle.svg';
import FutureListingApi from '../api/FutureListingApi';
import { NotificationManager } from 'react-notifications';
import { getFutureListing } from '../actions/future_listing';

const DeleteButton = ({ removeEmail }) => {
  return (
    <button className="trash-icon" onClick={removeEmail}>
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="trash"
        className="svg-inline--fa fa-trash fa-w-14 clickable trash-icon"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        style={{ visibility: 'unset' }}
      >
        <path
          fill="currentColor"
          d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"
        ></path>
      </svg>
    </button>
  );
};

const InviteBuyers = () => {
  const user = useSelector((state) => state.auth.user);

  const [emails, setEmails] = useState(['']);
  const [inputList, setInputList] = useState([{ firstName: "", lastName: "" ,email:""}]);
  const [logo, setlogo] = useState(['']);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleAddAnotherEmail = () => {
    setInputList([...inputList, { firstName: "", lastName: "" ,email:""}]);
    setEmails([...emails, '']);
  };

  const handleChange = (selectedIndex, value) => {
    setEmails(
      emails.map((email, index) => {
        if (index === selectedIndex) {
          return value;
        }
        return email;
      }),
    );
  };

  const handleNameChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const imagerror = () => {
    setlogo('')
  };

  const future_listing_state = useSelector((state) => state.future_listing.data);

  useEffect(() => {
    dispatch(getFutureListing());
  }, [dispatch]);

  useEffect(() => {
    if (future_listing_state && future_listing_state.logo) {
      setlogo(future_listing_state.logo);
    }
  }, [future_listing_state]);



  const handleSend = async () => {
    setLoading(true);
    try {
      const res = await FutureListingApi.inviteBuyers({
        email: emails,
        name: inputList,
        link:
          'http://future-listing.s3-website-us-east-1.amazonaws.com/buyer/future-listing/' +
          user.id,
      });

      if (res.status === 200) {
        NotificationManager.success('Invite has been sent.', 'Success');
      } else {
        setError(res.data.message);
        //NotificationManager.error('Invite has not been sent.', 'Error');
      }
    } catch (e) {
      if (e.response.data) setError(e.response.data.message);
    }
    setLoading(false);
  };

  const handleRemoveEmail = (index) => {
    setInputList(
      inputList.filter((name, i) => {
        return i !== index;
      }),
    );
  };



  return (
    <Layout>
      <h3 className="title">Future Listing Page Sender</h3>
      <div className="container">
        <p className="paragraph">
            Hi {user.first_name}<br/>
            Please send the Future Listing opportunity to all your prospects and build loyalty and guaranteed future income for life! <br/>
            <div className="logo_img">
              {logo ? <img src={logo} width="100" height="100" alt="" onError={() => imagerror()}/>  : ''}
            </div>
            <i>“Dig Your Well Before You’re Thirsty”  - Harvey MacKay</i>
        </p>
        <div className="white-card">
          {inputList.map((x, index) => {
              return (
                <div className="form__item invite-email-item" key={index}>
                  <div className="form__item_line">
                    <input
                      type="text"
                      id={'firstName' + index}
                      name="firstName"
                      placeholder="First Name"
                      value={x.firstName}
                      onChange={(e) => handleNameChange(e,index)}
                    />
                  </div>
                  <div className="form__item_line">
                    <input
                      type="text"
                      id={'lastName' + index}
                      name="lastName"
                      placeholder="Last Name"
                      value={x.lastName}
                      onChange={(e) => handleNameChange(e,index)}
                    />
                  </div>
                  <div className="form__item_line">
                    <input
                      type="email"
                      id={'email' + index}
                      name="email"
                      placeholder="Email"
                      value={x.email}
                      onChange={(e) => handleNameChange(e,index)}
                    />
                    <DeleteButton removeEmail={() => handleRemoveEmail(index)} />
                  </div>
                </div>
              );
            })}
            {/* {emails.map((email, index) => {
              return (
                <div className="form__item invite-email-item" key={index}>
                  <div className="form__item_line">
                    <input
                      type="email"
                      id={'email' + index}
                      placeholder="Email"
                      value={email}
                      onChange={(e) => handleChange(index, e.target.value)}
                    />
                    <DeleteButton removeEmail={() => handleRemoveEmail(index)} />
                  </div>
                </div>
              );
            })} */}
          <div className="form__item text-center">
            <button className="add-another-btn" onClick={handleAddAnotherEmail}>
              <span>Add another email</span>
              <img src={plusImg} alt="Plus Circle Icon" />
            </button>
          </div>

          {error && (
            <div className="form__item text-center">
              <p className="error-msg">{error}</p>
            </div>
          )}

          <div className="actions">
            <button className="primary-btn" disabled={loading} onClick={handleSend}>
              Send
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default InviteBuyers;
