import { combineReducers } from 'redux';
import auth from './auth';
import company from './company';
import admin from './admin';
import future_listing from './future_listing';
import prospective_clients from './prospective_clients';

export default combineReducers({
  auth,
  company,
  admin,
  future_listing,
  prospective_clients
});
