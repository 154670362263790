// import IntroVideo from '../assets/future-listing-buyer-intro.mov';
import IntroVideo from '../assets/future-listing-video.mp4';

const EmbedVideo = ({ embedId }) => {
  return (
    <div className="embed-video">
      {/* <iframe
        src={Intro}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      /> */}
      <video controls  loop  fluid={false} width="100%">
        <source src={IntroVideo} type="video/mp4"></source>
      </video>
    </div>
  );
};

export default EmbedVideo;
