import ActionTypes from '../constants/actionTypes';

let token = localStorage.getItem('token');
let user = localStorage.getItem('user');

const initialState =
  token && user
    ? {
        authenticated: true,
        token: token,
        loading: false,
        email_verified: true,
        user: JSON.parse(user),
        error: '',
        success: '',
      }
    : {
        authenticated: false,
        token: '',
        email_verified: false,
        loading: false,
        user: {},
        error: '',
        success: '',
      };

export default function auth(state = initialState, action) {
  let { payload } = action;
  switch (action.type) {
    case ActionTypes.AUTH_BUYER_SIGNUP.REQUEST:
    case ActionTypes.AUTH_REALTOR_SIGNUP.REQUEST:
    case ActionTypes.AUTH_LOAN_OFFICER_SIGNUP.REQUEST:
    case ActionTypes.AUTH_CREATE_PASSWORD.REQUEST:
    case ActionTypes.AUTH_FORGOT_PASSWORD.REQUEST:
    case ActionTypes.AUTH_LOGIN.REQUEST:
    case ActionTypes.GET_USER_PROFILE.REQUEST:
    case ActionTypes.UPDATE_USER_PROFILE.REQUEST:
    case ActionTypes.CLEAR_AUTH_STATE:
      return {
        ...state,
        error: '',
        success: '',
        loading: true,
      };
    case ActionTypes.AUTH_BUYER_SIGNUP.SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case ActionTypes.AUTH_REALTOR_SIGNUP.SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case ActionTypes.AUTH_LOAN_OFFICER_SIGNUP.SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case ActionTypes.AUTH_CREATE_PASSWORD.SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case ActionTypes.AUTH_FORGOT_PASSWORD.SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case ActionTypes.AUTH_LOGIN.SUCCESS:
      return {
        ...state,
        error: '',
        success: payload.message,
        authenticated: true,
        token: payload.user.token,
        user: payload.user,
        loading: false,
      };
    case ActionTypes.AUTH_LOGOUT.SUCCESS:
      return {
        ...state,
        authenticated: false,
        token: '',
      };
    case ActionTypes.GET_USER_PROFILE.SUCCESS:
      return {
        ...state,
        user: payload,
      };
    case ActionTypes.UPDATE_USER_PROFILE.SUCCESS:
      return {
        ...state,
      };
    case ActionTypes.AUTH_BUYER_SIGNUP.FAILURE:
    case ActionTypes.AUTH_REALTOR_SIGNUP.FAILURE:
    case ActionTypes.AUTH_LOAN_OFFICER_SIGNUP.FAILURE:
    case ActionTypes.AUTH_CREATE_PASSWORD.FAILURE:
    case ActionTypes.AUTH_FORGOT_PASSWORD.FAILURE:
    case ActionTypes.AUTH_LOGIN.FAILURE:
    case ActionTypes.GET_USER_PROFILE.FAILURE:
    case ActionTypes.UPDATE_USER_PROFILE.FAILURE:
      console.log('failure payload', payload.response);
      return {
        ...state,
        error: payload.response.data.message,
        success: '',
        loading: false,
      };
    default:
      return state;
  }
}
