import axios from 'axios';
import BaseApi from './BaseApi';

class ProspectiveClientsApi extends BaseApi {
  getProspectiveClients() {
    return axios.get(this.REACT_APP_SERVER_URL + `/prospective-clients`, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  Unsubscribe(id) {
    return axios.get(this.REACT_APP_SERVER_URL + `/unsubscribe/` + id);
  }
}

export default new ProspectiveClientsApi();
