import axios from 'axios';
import BaseApi from './BaseApi';

class FutureListingApi extends BaseApi {
  getFutureListing() {
    return axios.get(this.REACT_APP_SERVER_URL + `/future-listing`, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  getBuyerFutureListing(id) {
    return axios.get(this.REACT_APP_SERVER_URL + `/buyer/future-listing/` + id, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  searchBuyerFutureListing(id, filters) {
    return axios.post(this.REACT_APP_SERVER_URL + `/buyer/future-listing/` + id, filters);
  }

  create(data) {
    return axios.post(this.REACT_APP_SERVER_URL + '/future-listing', data, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  uploadImage(data) {
    return axios.post(this.REACT_APP_SERVER_URL + '/s3-file-upload', data, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  inviteBuyers(data) {
    return axios.post(this.REACT_APP_SERVER_URL + '/invite-buyers', data, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }
}

export default new FutureListingApi();
