import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../../actions/auth';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [alert, setAlert] = useState('');

  const handleChange = (value) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!value) {
      setError('This field should not be empty');
    } else if (!re.test(value)) {
      setError('Invalid e-mail address');
    } else {
      setError('');
    }
    setEmail(value);
  };

  const sendResetPassword = async () => {
    if (!email) {
      setError('This field should not be empty');
      return;
    }

    let resp = await dispatch(forgotPassword({ email: email }));
    console.log(resp);
    setAlert(resp.payload.message);
  };

  return (
    <div className="auth__page">
      <div className="auth__page_card">
        <h3 className="title">Forgot Password</h3>
        <div className="form__item">
          <div className="form__item_inner">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              onChange={(e) => handleChange(e.target.value)}
            />
            {error && <p className="error-msg">{error}</p>}
          </div>
        </div>
        {alert && <p className="success-msg">{alert}</p>}
        <button className="primary-btn" onClick={() => sendResetPassword()}>
          Send
        </button>
      </div>
    </div>
  );
};

export default ForgotPassword;
