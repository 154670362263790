import { useHistory } from 'react-router-dom';
import arrowImg from '../../assets/images/arrow.svg';
import thanksImg from '../../assets/images/thanks.svg';
import '../../assets/styles/auth.scss';

const ThanksPage = () => {
  const history = useHistory();

  const goToIndexPage = () => {
    history.push('/');
  };

  return (
    <div className="auth__page">
      <div className="auth__page_card">
        <div className="back-link" onClick={goToIndexPage}>
          <img src={arrowImg} alt="" />
          <span>Back</span>
        </div>
        <div className="auth__page_card_inner center">
          <div className="main-image">
            <img src={thanksImg} alt="" />
          </div>
          <h3 className="title">Thanks for signing up!</h3>
          <p className="thanks-description">
            We are reviewing your profile now. We will notify you of our approval decision via
            email.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ThanksPage;
