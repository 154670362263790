import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import manImg from '../../assets/images/man.svg';
import loanImg from '../../assets/images/loan.svg';
import '../../assets/styles/auth.scss';
import RealtorSignup from './RealtorSignup';
import LoanOfficerSignup from './LoanOfficerSignup';

const accountTypes = [
  { id: 1, text: 'I’m a Realtor', icon: manImg },
  { id: 2, text: 'I’m a Loan Officer', icon: loanImg },
];

const SignUp = () => {
  const history = useHistory();
  const [selectedType, setSelectedType] = useState(0);

  const goToThanksPage = () => {
    history.push('/thanks');
  };

  const goToIndexPage = () => {
    setSelectedType(0);
  };

  return (
    <div className="auth__page">
      <div className="auth__page_card">
        {selectedType === 0 && (
          <>
            <h3 className="title">Registration</h3>
            <p className="guide-text">Select your account type</p>
            <div className="account__types">
              {accountTypes.map((type, index) => (
                <div
                  className="account__types_item"
                  key={index}
                  onClick={() => setSelectedType(type.id)}
                >
                  <div className="icon-part">
                    <img src={type.icon} alt="type icon" />
                  </div>
                  <p>{type.text}</p>
                </div>
              ))}
            </div>
          </>
        )}
        {selectedType === 1 && (
          <RealtorSignup goToIndexPage={goToIndexPage} goToThanksPage={goToThanksPage} />
        )}
        {selectedType === 2 && (
          <LoanOfficerSignup goToIndexPage={goToIndexPage} goToThanksPage={goToThanksPage} />
        )}
      </div>
    </div>
  );
};

export default SignUp;
