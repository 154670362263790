import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import { NotificationManager } from 'react-notifications';
import 'react-phone-input-2/lib/style.css';
import { realtorSignup, clearState } from '../../actions/auth';
import arrowImg from '../../assets/images/arrow.svg';
import closeImg from '../../assets/images/close.svg';
import { addCompany, getAllCompanies } from '../../actions/company';
import '../../assets/styles/modal.scss';

const customStyles = {};

const RealtorSignup = ({ goToIndexPage }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const realEstateAgentCompanies = useSelector((state) =>
    state.company.realtor_companies.map((item) => {
      return { label: item.name, value: item.id };
    }),
  );

  console.log('realEstateAgentCompanies', realEstateAgentCompanies);

  const systemError = useSelector((state) => state.auth.error);

  const [formData, setFormData] = useState({
    name: '',
    license_number: '',
    phone_number: '',
    email: '',
    real_estate_company: '',
  });

  const [formDataError, setFormDataError] = useState({
    name: '',
    license_number: '',
    phone_number: '',
    email: '',
    real_estate_company: '',
    terms: '',
  });

  const [showCreateModal, setShowCreateModal] = useState(false);

  useEffect(() => {
    dispatch(clearState());
    dispatch(getAllCompanies());
  }, [dispatch]);

  const [selectedCompany, setSelectedCompany] = useState();
  const handleChange = (option, stateName) => {
    const name = stateName.name;
    setSelectedCompany(option);

    setFormDataError((formDataError) => ({ ...formDataError, [name]: '' }));
    setFormData({ ...formData, [name]: option.value });
  };

  const [termChecks, setTermChecks] = useState(false);

  const handleChecks = () => {
    setTermChecks(!termChecks);
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const validateInput = (value, stateName) => {
    if (!value) {
      setFormDataError((formDataError) => ({
        ...formDataError,
        [stateName]: 'This field should not be empty',
      }));
      return false;
    }

    if (stateName === 'email') {
      const emailValid = validateEmail(value);

      if (!emailValid) {
        setFormDataError((formDataError) => ({
          ...formDataError,
          [stateName]: 'Invalid e-mail address',
        }));
        return false;
      } else {
        setFormDataError((formDataError) => ({ ...formDataError, [stateName]: '' }));
        return true;
      }
    } else {
      setFormDataError((formDataError) => ({ ...formDataError, [stateName]: '' }));
      return true;
    }
  };

  const onChange = (value, stateName) => {
    validateInput(value, stateName);
    setFormData({ ...formData, [stateName]: value });
  };

  const handleSignup = async () => {
    const result = Object.keys(formData).map((key) => {
      return validateInput(formData[key], key);
    });

    setFormDataError((formDataError) => ({
      ...formDataError,
      terms: !termChecks
        ? 'Please indicate that you have read and agree to the Terms and Conditions'
        : '',
    }));
    const isInvalid = result.filter((r) => !r).length > 0;

    if (isInvalid || !termChecks) {
      return;
    }

    let resp = await dispatch(
      realtorSignup({
        name: formData.name,
        license_number: formData.license_number,
        email: formData.email,
        phone_number: formData.phone_number,
        real_estate_company: formData.real_estate_company,
      }),
    );

    if (resp && resp.type == 'AUTH_REALTOR_SIGNUP_SUCCESS') {
      NotificationManager.success('Signup successful', 'Success!');
      history.push('thanks');
    }
  };

  const [realEstateName, setRealEstateName] = useState();
  const [brokerageNumber, setBrokerageNumber] = useState();
  const [realEstateNameError, setRealEstateNameError] = useState();
  const [brokerageNumberError, setBrokerageNumberError] = useState();

  const handleChangeName = (value) => {
    if (!value) {
      setRealEstateNameError('This field should not be empty');
      return false;
    } else {
      setRealEstateName(value);
      setRealEstateNameError('');
      return true;
    }
  };

  const handleChangeBrokerageNumber = (value) => {
    if (!value) {
      setBrokerageNumberError('This field should not be empty');
      return false;
    } else {
      setBrokerageNumber(value);
      setBrokerageNumberError('');
      return true;
    }
  };

  const addRealEstateCompany = async () => {
    if (!realEstateName) {
      setRealEstateNameError('This field should not be empty');
      return false;
    }

    let resp = await dispatch(addCompany(realEstateName, 1));

    if (resp.type === 'ADD_COMPANY_SUCCESS') {
      dispatch(getAllCompanies());
      setShowCreateModal(false);
    }
  };

  return (
    <>
      <div className="back-link" onClick={goToIndexPage}>
        <img src={arrowImg} alt="" />
        <span>Back</span>
      </div>
      <div className="auth__page_card_inner">
        <h3>Realtor Information</h3>
        <div className="form__item">
          <div className="form__item_inner">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              onChange={(e) => onChange(e.target.value, 'name')}
            />
          </div>
          {formDataError.name && <p className="error-msg">{formDataError.name}</p>}
        </div>
        <div className="form__item">
          <div className="form__item_inner">
            <label htmlFor="real_estate_company">Select your company</label>
            <Select
              value={selectedCompany}
              onChange={handleChange}
              options={realEstateAgentCompanies}
              styles={customStyles}
              className="custom-select"
              classNamePrefix="custom-select"
              name="real_estate_company"
              rules={{ required: 'Please select an option' }}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
            {formDataError.real_estate_company && (
              <p className="error-msg">{formDataError.real_estate_company}</p>
            )}
          </div>
        </div>
        <p className="add-one" onClick={() => setShowCreateModal(true)}>
          Don't see your company? Add a new one
        </p>
        <div className="form__item">
          <div className="form__item_inner">
            <label htmlFor="license_number">License Number</label>
            <input
              type="text"
              id="license_number"
              name="license_number"
              onChange={(e) => onChange(e.target.value, 'license_number')}
            />
          </div>
          {formDataError.license_number && (
            <p className="error-msg">{formDataError.license_number}</p>
          )}
        </div>
        <div className="form__item">
          <div className="form__item_inner">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              onChange={(e) => onChange(e.target.value, 'email')}
            />
          </div>
          {formDataError.email && <p className="error-msg">{formDataError.email}</p>}
        </div>
        <div className="form__item">
          <div className="form__item_inner">
            <label htmlFor="phone_number">Phone Number</label>
            <PhoneInput
              country={'us'}
              value={formData.phone_number}
              onlyCountries={['us']}
              onChange={(phone) => onChange(`+${phone}`, 'phone_number')}
              inputProps={{
                name: 'phone_number',
                required: true,
              }}
            />
          </div>
          {formDataError.phone_number && <p className="error-msg">{formDataError.phone_number}</p>}
        </div>
        <div className="CustomCheckBox">
          <input
            type="checkbox"
            checked={termChecks}
            id="differentBillingAddress"
            name="differentBillingAddress"
            onChange={() => handleChecks()}
          />
          <label htmlFor="differentBillingAddress">
            I accept the <Link onClick={()=> window.open("/terms-and-conditions/realtor", "_blank")}>Terms and Conditions</Link>
          </label>
          {formDataError.terms && <p className="error-msg">{formDataError.terms}</p>}
        </div>
        {systemError && <p className="error-msg system-error">{systemError}</p>}
        <button className="primary-btn" onClick={() => handleSignup()}>
          Complete Signup
        </button>
      </div>
      {showCreateModal && (
        <div className="custom-modal">
          <div className="modal-card">
            <img
              src={closeImg}
              alt=""
              className="close-icon"
              onClick={() => setShowCreateModal(false)}
            />
            <h3>Add New Real Estate Agent Company</h3>
            <div className="form__item">
              <div className="form__item_inner">
                <label htmlFor="company_name">Company name</label>
                <input
                  type="text"
                  id="company_name"
                  name="company_name"
                  onChange={(e) => handleChangeName(e.target.value)}
                />
              </div>
              {realEstateNameError && <p className="error-msg">{realEstateNameError}</p>}
            </div>
            <div className="form__item">
              <div className="form__item_inner">
                <label htmlFor="brokerage_firm_dre_license_number">
                  Brokerage Firm DRE License Number
                </label>
                <input
                  type="text"
                  id="brokerage_firm_dre_license_number"
                  name="brokerage_firm_dre_license_number"
                  onChange={(e) => handleChangeBrokerageNumber(e.target.value)}
                />
              </div>
              {brokerageNumberError && <p className="error-msg">{brokerageNumberError}</p>}
            </div>
            <div className="actions">
              <button className="primary-btn" onClick={() => addRealEstateCompany()}>
                ADD
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RealtorSignup;
