import axios from 'axios';
import BaseApi from './BaseApi';

class AuthApi extends BaseApi {
  buyerSignup(data) {
    return axios.post(this.REACT_APP_SERVER_URL + '/buyer-signup', data);
  }

  realtorSignup(data) {
    return axios.post(this.REACT_APP_SERVER_URL + '/realtor-signup', data);
  }

  loanOfficerSignup(data) {
    return axios.post(this.REACT_APP_SERVER_URL + '/loanofficer-signup', data);
  }

  createPassword(url, data) {
    return axios.put(this.REACT_APP_SERVER_URL + url, data);
  }

  forgotPassword(data) {
    return axios.post(this.REACT_APP_SERVER_URL + '/forgot-password', data);
  }

  login(data) {
    return axios.post(this.REACT_APP_SERVER_URL + '/login', data);
  }

  getUserProfile() {
    return axios.get(this.REACT_APP_SERVER_URL + `/user-profile`, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  updateUserProfile(data) {
    return axios.put(this.REACT_APP_SERVER_URL + '/user-profile', data, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }
}

export default new AuthApi();
