import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../components/Layout';
import '../assets/styles/profile-settings.scss';
import avatarImg from '../assets/images/avatar.svg';
import { getUserProfile, updateUserProfile } from '../actions/auth';
import FutureListingApi from '../api/FutureListingApi';
import { NotificationManager } from 'react-notifications';

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const user = useSelector((state) => state.auth.user);
  const error = useSelector((state) => state.auth.error);

  const [userObj, setUserObj] = useState(user);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    setUserObj({ ...user, old_password: '', new_password: '' });
  }, [user]);

  const handleSaveProfile = async () => {
    const res = await dispatch(updateUserProfile(userObj));

    if (res.type === 'UPDATE_USER_PROFILE_SUCCESS') {
      NotificationManager.success('User Profile has been saved.', 'Success');
    }
  };

  const handleChange = (e) => {
    setUserObj({ ...userObj, [e.target.name]: e.target.value });
  };

  const handleUploadLogo = async (files) => {
    const file = files[0];
    let formData = new FormData();
    formData.append('image', file);

    /** Make Image Preview Url */
    let reader = new FileReader();
    reader.onloadend = () => {
      setUserObj({ ...userObj, profile_pic: reader.result });
    };
    reader.readAsDataURL(file);

    /** Upload Image to S3 */
    const res = await FutureListingApi.uploadImage(formData);
    console.log(res);
    if (res && res.data) {
      console.log('upload success', res.data);
      setUserObj({ ...userObj, profile_pic: res.data.fileUrl });
    }
  };

  return (
    <Layout>
      <div className="profile-settings">
        <h3 className="title">Profile Setting</h3>

        <div className="container">
          <div className="white-card padding-sm">
            <h5>Upload picture or logo</h5>
            <div className="form__item">
              <div className="avatar-section">
                <div className="main-image">
                  <img src={userObj.profile_pic ? userObj.profile_pic : avatarImg} alt="" />
                </div>
                <div>
                  <input
                    type="file"
                    ref={ref}
                    id="upload-company-logo"
                    accept="image/*"
                    onChange={(e) => handleUploadLogo(e.target.files)}
                  />
                  <button onClick={() => ref.current.click()}>Change</button>
                </div>
              </div>
            </div>
          </div>

          <div className="white-card padding-sm">
            <h5>Profile Info</h5>
            <div className="form__item">
              <div className="form__item_inner">
                <label htmlFor="first_name">First Name</label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  value={userObj.first_name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form__item">
              <div className="form__item_inner">
                <label htmlFor="last_name">Last Name</label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  value={userObj.last_name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form__item">
              <div className="form__item_inner">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  disabled
                  value={userObj.email}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="white-card padding-sm">
            <h5>Change Password</h5>
            <div className="form__item">
              <div className="form__item_inner">
                <label htmlFor="old_password">Old Password</label>
                <input
                  type="password"
                  id="old_password"
                  name="old_password"
                  value={userObj.old_password}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form__item">
              <div className="form__item_inner">
                <label htmlFor="new_password">New Password</label>
                <input
                  type="password"
                  id="new_password"
                  name="new_password"
                  value={userObj.new_password}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form__item">
              <div className="form__item_inner">
                {error && <p className="error-msg">{error}</p>}
              </div>
            </div>
            <div className="actions" onClick={handleSaveProfile}>
              <button className="primary-btn">Save</button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProfileSettings;
