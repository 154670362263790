import React from 'react';
import Layout from '../../components/Layout';
import CompanyList from './CompanyList';
import '../../assets/styles/admin.scss';

const CompaniesPage = () => {
  return (
    <Layout>
      <div className="admin-page">
        <div className="content">
          <CompanyList />
        </div>
      </div>
    </Layout>
  );
};

export default CompaniesPage;
