import React, { useState, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../actions/auth';
import helpImg from '../assets/images/help.svg';
import logo from '../assets/images/logo.png';
import Avatar from 'react-avatar';

import { useOnClickOutside } from '../utils';

const Navbar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const ref = useRef();
  const exceptional = useRef();
  const user = useSelector((state) => state.auth.user);

  const [showMenuActions, setShowMenuActions] = useState(false);
  useOnClickOutside(ref, () => setShowMenuActions(false), exceptional);

  const handleLogout = () => {
    dispatch(logout());
    history.push('/');
  };

  if (!user.id) return '';

  return (
    <div className="navbar">
      <div className="logo nav-item">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <div className="tabs">
        {user.user_type === 'Admin' && (
          <>
            <Link to="/admin/users">
              <div
                className={`tab-item ${
                  history.location.pathname === '/admin/users' ? 'selected' : ''
                }`}
              >
                User List
              </div>
            </Link>
            <Link to="/admin/buyers">
              <div
                className={`tab-item ${
                  history.location.pathname === '/admin/buyers' ? 'selected' : ''
                }`}
              >
                Buyers Queue
              </div>
            </Link>
            <Link to="/admin/companies">
              <div
                className={`tab-item ${
                  history.location.pathname === '/admin/companies' ? 'selected' : ''
                }`}
              >
                Company List
              </div>
            </Link>
          </>
        )}
        {(user.user_type === 'Realtor' || user.user_type === 'Loan Officer') && (
          <>
            <Link to="/create-future-listing">
              <div
                className={`tab-item ${
                  history.location.pathname === '/create-future-listing' ? 'selected' : ''
                }`}
              >
                Create Future Listing Page
              </div>
            </Link>
            <Link to="/invite-buyers">
              <div
                className={`tab-item ${
                  history.location.pathname === '/invite-buyers' ? 'selected' : ''
                }`}
              >
                Future Listing Page Sender
              </div>
            </Link>
            <Link to="/prospective-clients">
              <div
                className={`tab-item ${
                  history.location.pathname === '/prospective-clients' ? 'selected' : ''
                }`}
              >
                Prospective Clients
              </div>
            </Link>
          </>
        )}
      </div>
      <div className="navs">
        <div className="help nav-item">
          <img src={helpImg} alt="help icon" />
          <span>Help</span>
        </div>
        {user && user.id && (
          <>
            <div className="avatar-section">
              <span className="username">
                {user.user_type === 'Admin' ? 'Admin' : user.first_name + ' ' + user.last_name}
              </span>
              <span onClick={() => setShowMenuActions(!showMenuActions)} ref={exceptional}>
                {user.profile_pic ? (
                  <img className="avatar-image" alt="avatar" src={user.profile_pic} />
                ) : (
                  <Avatar
                    color={Avatar.getRandomColor(user.first_name + ' ' + user.last_name, [
                      'red',
                      'green',
                      'blue',
                    ])}
                    round={true}
                    name={user.first_name + ' ' + user.last_name}
                    size={40}
                  />
                )}
              </span>
            </div>
            {showMenuActions && (
              <div className="menu-actions" ref={ref}>
                <div className="action-item">
                  <Link to="/profile">Edit Profile</Link>
                </div>
                <div className="action-item" onClick={handleLogout}>
                  <p>Logout</p>
                </div>
              </div>
            )}
            {/* <div className="nav-item">
              <Link to="/profile">
                <p>
                  {user.user_type === 'Admin' ? 'Admin' : user.first_name + ' ' + user.last_name}
                </p>
              </Link>
            </div>
            <div className="nav-item logout" onClick={handleLogout}>
              <img src={logoutImg} alt="" />
            </div> */}
          </>
        )}
      </div>
    </div>
  );
};

export default Navbar;
