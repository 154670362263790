import { useState } from 'react';

const Tabs = ({ originalTabs, handleSelectedTab }) => {
  const [tabs, setTabs] = useState(originalTabs);

  const handleTabs = (tab) => {
    handleSelectedTab(tab.id);
    setTabs(
      originalTabs.map((item) => {
        if (item.id === tab.id) {
          return { ...item, selected: true };
        } else {
          return { ...item, selected: false };
        }
      }),
    );
  };

  return (
    <div className="tabs">
      {tabs.map((item, index) => (
        <div
          className={`tab-item ${item.selected ? 'selected' : ''}`}
          key={index}
          onClick={() => handleTabs(item)}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
