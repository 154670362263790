import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import arrowImg from '../../assets/images/arrow.svg';
import closeImg from '../../assets/images/close.svg';
import { addCompany, getAllCompanies } from '../../actions/company';
import AddUserSuccess from './AddUserSuccess';

import { loanOfficerSignup, clearState } from '../../actions/auth';

const customStyles = {};

const AddLoanOfficer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedLoanOfficeCompany, setSelectedLoanOfficeCompany] = useState();
  const [selectedRealAgentCompanies, setSelectedRealAgentCompanies] = useState();
  const realEstateAgentCompanies = useSelector((state) =>
    state.company.realtor_companies.map((item) => {
      return { label: item.name, value: item.id };
    }),
  );

  const systemError = useSelector((state) => state.auth.error);

  const loanOfficerCompanies = useSelector((state) =>
    state.company.loan_companies.map((item) => {
      return { label: item.name, value: item.id };
    }),
  );

  const [formData, setFormData] = useState({
    name: '',
    license_number: '',
    phone_number: '',
    email: '',
    loan_officer_company: '',
    real_estate_company: [],
  });

  const [formDataError, setFormDataError] = useState({
    name: '',
    license_number: '',
    phone_number: '',
    email: '',
    loan_officer_company: '',
    real_estate_company: '',
    terms: '',
  });

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loanOfficerName, setLoanOfficerName] = useState('');
  const [loanOfficerNameError, setLoanOfficerNameError] = useState(false);

  useEffect(() => {
    dispatch(clearState());
    dispatch(getAllCompanies());
  }, [dispatch]);

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const validateInput = (value, stateName) => {
    if (stateName === 'real_estate_company') {
      if (!value.length) {
        setFormDataError((formDataError) => ({
          ...formDataError,
          [stateName]: 'This field should not be empty',
        }));
        return false;
      }
    } else {
      if (!value) {
        setFormDataError((formDataError) => ({
          ...formDataError,
          [stateName]: 'This field should not be empty',
        }));
        return false;
      }
    }

    if (stateName === 'email') {
      const emailValid = validateEmail(value);

      if (!emailValid) {
        setFormDataError((formDataError) => ({
          ...formDataError,
          [stateName]: 'Invalid e-mail address',
        }));
        return false;
      } else {
        setFormDataError((formDataError) => ({ ...formDataError, [stateName]: '' }));
        return true;
      }
    } else {
      setFormDataError((formDataError) => ({ ...formDataError, [stateName]: '' }));
      return true;
    }
  };

  const onChange = (value, stateName) => {
    validateInput(value, stateName);
    setFormData({ ...formData, [stateName]: value });
  };

  const handleChange = (option, stateName) => {
    const name = stateName.name;
    if (name === 'real_estate_company') {
      setSelectedRealAgentCompanies(option);
      setFormData({ ...formData, [name]: option.map((item) => item.value) });
    } else if (name === 'loan_officer_company') {
      setSelectedLoanOfficeCompany(option);
      setFormData({ ...formData, [name]: option.value });
    }

    setFormDataError((formDataError) => ({ ...formDataError, [name]: '' }));
  };

  const handleChangeName = (value) => {
    if (!value) {
      setLoanOfficerNameError('This field should not be empty');
      return false;
    } else {
      setLoanOfficerName(value);
      setLoanOfficerNameError('');
      return true;
    }
  };

  const addLoanOfficerCompany = async () => {
    if (!loanOfficerName) {
      setLoanOfficerNameError('This field should not be empty');
      return false;
    }

    let resp = await dispatch(addCompany(loanOfficerName, 2));

    if (resp.type === 'ADD_COMPANY_SUCCESS') {
      setShowCreateModal(false);
    }
  };

  const handleSignup = async () => {
    const result = Object.keys(formData).map((key) => {
      return validateInput(formData[key], key);
    });

    if (result.filter((r) => !r).length > 0) {
      return;
    }

    let resp = await dispatch(
      loanOfficerSignup({
        name: formData.name,
        license_number: formData.license_number,
        email: formData.email,
        phone_number: formData.phone_number,
        real_estate_company: formData.real_estate_company,
        loan_officer_company: formData.loan_officer_company,
      }),
    );

    if (resp && resp.type == 'AUTH_LOAN_OFFICER_SIGNUP_SUCCESS') {
      setShowSuccessModal(true);
    }
  };

  return (
    <div className="auth__page">
      <div className="auth__page_card">
        <div className="back-link" onClick={history.goBack}>
          <img src={arrowImg} alt="" />
          <span>Back</span>
        </div>
        <div className="auth__page_card_inner">
          <h3>Loan Officer Company Information</h3>
          <div className="form__item">
            <div className="form__item_inner">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                onChange={(e) => onChange(e.target.value, 'name')}
              />
            </div>
            {formDataError.name && <p className="error-msg">{formDataError.name}</p>}
          </div>
          <div className="form__item">
            <div className="form__item_inner">
              <label htmlFor="loan_officer_company">Loan Officer Company</label>
              <Select
                value={selectedLoanOfficeCompany}
                onChange={handleChange}
                options={loanOfficerCompanies}
                styles={customStyles}
                className="custom-select"
                classNamePrefix="custom-select"
                name="loan_officer_company"
                rules={{ required: 'Please select an option' }}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              />
              {formDataError.loan_officer_company && (
                <p className="error-msg">{formDataError.loan_officer_company}</p>
              )}
            </div>
          </div>
          <p className="add-one" onClick={() => setShowCreateModal(true)}>
            Don't see your company? Add a new one
          </p>
          <div className="form__item">
            <div className="form__item_inner">
              <label htmlFor="real_estate_company">Real Estate Agent Company</label>
              <Select
                value={selectedRealAgentCompanies}
                onChange={handleChange}
                options={realEstateAgentCompanies}
                styles={customStyles}
                className="custom-select"
                classNamePrefix="custom-select"
                name="real_estate_company"
                rules={{ required: 'Please select an option' }}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                isMulti
              />
              {formDataError.real_estate_company && (
                <p className="error-msg">{formDataError.real_estate_company}</p>
              )}
            </div>
          </div>
          <div className="form__item">
            <div className="form__item_inner">
              <label htmlFor="license_number">License Number</label>
              <input
                type="text"
                id="license_number"
                name="license_number"
                onChange={(e) => onChange(e.target.value, 'license_number')}
              />
            </div>
            {formDataError.license_number && (
              <p className="error-msg">{formDataError.license_number}</p>
            )}
          </div>
          <div className="form__item">
            <div className="form__item_inner">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={(e) => onChange(e.target.value, 'email')}
              />
            </div>
            {formDataError.email && <p className="error-msg">{formDataError.email}</p>}
          </div>
          <div className="form__item">
            <div className="form__item_inner">
              <label htmlFor="phone_number">Phone Number</label>
              <PhoneInput
                country={'us'}
                value={formData.phone_number}
                onlyCountries={['us']}
                onChange={(phone) => onChange(`+${phone}`, 'phone_number')}
                inputProps={{
                  name: 'phone_number',
                  required: true,
                }}
              />
            </div>
            {formDataError.phone_number && <p className="error-msg">{formDataError.phone_number}</p>}
          </div>
          {systemError && <p className="error-msg system-error">{systemError}</p>}
          <button className="primary-btn" onClick={handleSignup}>
            Add Loan Officer
          </button>
        </div>
        {showCreateModal && (
          <div className="custom-modal">
            <div className="modal-card">
              <img
                src={closeImg}
                alt=""
                className="close-icon"
                onClick={() => setShowCreateModal(false)}
              />
              <h3>Add New Loan Officer Company</h3>
              <div className="form__item">
                <div className="form__item_inner">
                  <label htmlFor="company_name">Company Name</label>
                  <input
                    type="text"
                    id="company_name"
                    name="company_name"
                    onChange={(e) => handleChangeName(e.target.value)}
                  />
                </div>
                {loanOfficerNameError && <p className="error-msg">{loanOfficerNameError}</p>}
              </div>
              <div className="actions">
                <button className="primary-btn" onClick={() => addLoanOfficerCompany()}>
                  ADD
                </button>
              </div>
            </div>
          </div>
        )}
        {showSuccessModal && <AddUserSuccess />}
      </div>
    </div>
  );
};

export default AddLoanOfficer;
