import Layout from '../components/Layout';
import EmbedVideo from '../components/EmbedVideo';
import '../assets/styles/home.scss';
import { useSelector } from 'react-redux';





const Home = () => {
  const user = useSelector((state) => state.auth.user);
  console.log(user);
  return (
    <Layout>
      <div className="home-page">
        {/* <h3 className="title">Hi! Check our video to introduce our Platform</h3> */}
        <h3 className="title">Hello {user.first_name}, check out the Future Listing platform that guarantees you income for life!</h3> 
        <div className="video-section">
          <EmbedVideo embedId="ScMzIvxBSi4" />
        </div>
        <div className="actions">
          <a className="primary-btn" href="/create-future-listing">
            Create Future listing page
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
