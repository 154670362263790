import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { login } from '../../actions/auth';
import logo from '../../assets/images/logo.png';
import '../../assets/styles/auth.scss';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const systemError = useSelector((state) => state.auth.error);

  const [formDataError, setFormDataError] = useState({
    email: '',
    password: '',
  });

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const validateInput = (value, stateName) => {
    if (!value) {
      setFormDataError((formDataError) => ({
        ...formDataError,
        [stateName]: 'This field should not be empty',
      }));
      return false;
    }

    if (stateName === 'email' && !validateEmail(value)) {
      setFormDataError((formDataError) => ({
        ...formDataError,
        [stateName]: 'Invalid e-mail address',
      }));
      return false;
    }

    setFormDataError((formDataError) => ({ ...formDataError, [stateName]: '' }));
    return true;
  };

  const onChange = (value, stateName) => {
    validateInput(value, stateName);
    setFormData({ ...formData, [stateName]: value });
  };

  const handleLogin = async () => {
    const result = Object.keys(formData).map((key) => {
      return validateInput(formData[key], key);
    });

    const isInvalid = result.filter((r) => !r).length > 0;

    if (isInvalid) {
      return;
    }

    let resp = await dispatch(
      login({
        email: formData.email,
        password: formData.password,
      }),
    );

    if (resp && resp.type == 'AUTH_LOGIN_SUCCESS') {
      console.log('user', resp.payload.user);
      const user_type = resp.payload.user.user_type;

      if (user_type === 'Admin') {
        history.push('/admin/users');
      } else if (user_type === 'Realtor' || user_type === 'Loan Officer') {
        history.push('/home');
      }
    }
  };

  return (
    <div className="auth__page">
      <div className="auth__page_card">
      <div className="shoe-container">
            <img src={logo} alt=""/>
        </div>
        <h3 className="title">Log In</h3>
        <div className="form__item">
          <div className="form__item_inner">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              onChange={(e) => onChange(e.target.value, 'email')}
            />
            {formDataError.email && <p className="error-msg">{formDataError.email}</p>}
          </div>
        </div>
        <div className="form__item">
          <div className="form__item_inner">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              onChange={(e) => onChange(e.target.value, 'password')}
            />
            {formDataError.password && <p className="error-msg">{formDataError.password}</p>}
          </div>
        </div>
        {systemError && <p className="error-msg system-error">{systemError}</p>}
        <div className="forgot-password-link">
          <Link to="/forgot-password">Forgot Password?</Link>
        </div>
        <button className="primary-btn" onClick={() => handleLogin()}>
          Log In
        </button>
        <div className="bottom-link">
          <p>
            Don't have an account?{' '}
            <Link to="/signup" className="default-link">
              Sign up now
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
