import ActionTypes from '../constants/actionTypes';

const initialState = {
  realtor_companies: [],
  loan_companies: [],
  companies: [],
  company: {},
  loading: false,
  error: '',
};

export default function company(state = initialState, action) {
  let { payload } = action;

  switch (action.type) {
    case ActionTypes.GET_ALL_COMPANIES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_ALL_COMPANIES.SUCCESS:
      return {
        ...state,
        companies: payload,
        realtor_companies: payload.filter(
          (company) => company.company_type === 'Real estate agent company',
        ),
        loan_companies: payload.filter(
          (company) => company.company_type === 'loan officer company',
        ),
        loading: false,
      };
    case ActionTypes.GET_ALL_COMPANIES.FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case ActionTypes.GET_COMPANY_DETAIL.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_COMPANY_DETAIL.SUCCESS:
      return {
        ...state,
        company: payload,
        loading: false,
      };
    case ActionTypes.GET_COMPANY_DETAIL.FAILURE:
      return {
        ...state,
        error: payload.error.message,
        loading: false,
      };
    case ActionTypes.ADD_COMPANY.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.ADD_COMPANY.SUCCESS:
      if (payload.company_type === 1) {
        return {
          ...state,
          companies: [...state.companies, payload],
          realtor_companies: [...state.realtor_companies, payload],
          loading: false,
        };
      } else {
        return {
          ...state,
          companies: [...state.companies, payload],
          loan_companies: [...state.loan_companies, payload],
          loading: false,
        };
      }
    case ActionTypes.ADD_COMPANY.FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case ActionTypes.UPDATE_COMPANY_STATUS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.UPDATE_COMPANY_STATUS.SUCCESS:
      if (payload.company_type === 'Real estate agent company') {
        return {
          ...state,
          companies: state.companies.map((c) => {
            return c.id === payload.id ? payload : c;
          }),
          realtor_companies: state.realtor_companies.map((c) => {
            return c.id === payload.id ? payload : c;
          }),
          company: payload,
          loading: false,
        };
      } else {
        return {
          ...state,
          companies: state.companies.map((c) => {
            return c.id === payload.id ? payload : c;
          }),
          loan_companies: state.loan_companies.map((c) => {
            return c.id === payload.id ? payload : c;
          }),
          company: payload,
          loading: false,
        };
      }
    case ActionTypes.UPDATE_COMPANY_STATUS.FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case ActionTypes.DELETE_COMPANY.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.DELETE_COMPANY.SUCCESS:
      return {
        ...state,
        companies: [...state.companies, payload],
        realtor_companies: [...state.realtor_companies, payload],
        loan_companies: [...state.loan_companies, payload],
        loading: false,
      };
    case ActionTypes.DELETE_COMPANY.FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
