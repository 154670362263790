import ActionTypes from '../constants/actionTypes';

const initialState = {
  data: {
    color1: '',
    color2: '',
    color3: '',
    logo: '',
  },
  buyerData: {
    color1: '',
    color2: '',
    color3: '',
    logo: '',
  },
  mortech_response: [],
  loading: false,
  error: '',
};

export default function company(state = initialState, action) {
  let { payload } = action;

  switch (action.type) {
    case ActionTypes.GET_FUTURE_LISTING.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_FUTURE_LISTING.SUCCESS:
      return {
        ...state,
        data: payload[0] ? payload[0] : initialState.data,
        loading: false,
      };
    case ActionTypes.GET_FUTURE_LISTING.FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case ActionTypes.GET_BUYER_FUTURE_LISTING.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_BUYER_FUTURE_LISTING.SUCCESS:
      return {
        ...state,
        buyerData: payload.data,
        mortech_response: payload.mortech_response,
        loading: false,
      };
    case ActionTypes.GET_BUYER_FUTURE_LISTING.FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case ActionTypes.CREATE_FUTURE_LISITING.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CREATE_FUTURE_LISITING.SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case ActionTypes.CREATE_FUTURE_LISITING.FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
