import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createFutureListing } from '../actions/future_listing';
import Layout from '../components/Layout';
import ImageFileUploader from '../components/ImageFileUploader';
import VideoFileUploader from '../components/VideoFileUploader';
import '../assets/styles/future-listing.scss';
import FutureListingApi from '../api/FutureListingApi';
import { getFutureListing } from '../actions/future_listing';
import { NotificationManager } from 'react-notifications';

const CreateFutureListing = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [formData, setFormData] = useState({
    image: '',
    video: '',
    url: '',
    color1: '#fff',
    color2: '#fff',
    color3: '#fff',
  });

  const [formDataError, setFormDataError] = useState({
    image: '',
    video: '',
    url: '',
    color1: '',
    color2: '',
    color3: '',
  });

  const [error, setError] = useState('');

  const [isDisabled, setIsDisabled] = useState(false);

  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [videoPreviewUrl, setVideoPreviewUrl] = useState('');

  const future_listing_state = useSelector((state) => state.future_listing.data);

  useEffect(() => {
    dispatch(getFutureListing());
  }, [dispatch]);

 
  useEffect(() => {
    setFormData(future_listing_state);
    if (future_listing_state) {
      setImagePreviewUrl(future_listing_state.logo);
      setVideoPreviewUrl(future_listing_state.video);
      setFormData({ ...formData, url: future_listing_state.loan_url });
    }
  }, [future_listing_state]);

  // const updateColor = (value, name) => {
  //   setFormData({ ...formData, [name]: value });
  // };

  const uploadImage = async (file) => {
    let imageFormData = new FormData();

    imageFormData.append('image', file);

    /** Make Image Preview Url */
    let reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);

    // disable button before image starts uploading
    setIsDisabled(true);

    /** Upload Image to S3 */
    const res = await FutureListingApi.uploadImage(imageFormData);
    if (res && res.data) {
      setFormData({ ...formData, image: res.data.fileUrl });
      setFormDataError({ ...formDataError, image: '' });
    }

    // enable button after image finishes uploading
    setIsDisabled(false);
  };

  const uploadVideo = async (file) => {
    let videoFormData = new FormData();

    videoFormData.append('video', file);

    /** Make Video Preview Url */
    setVideoPreviewUrl(URL.createObjectURL(file));

    // disable button before video starts uploading
    setIsDisabled(true);

    /** Upload Video to S3 */
    const res = await FutureListingApi.uploadImage(videoFormData);
      if (res && res.data) {
        setFormData({ ...formData, video: res.data.fileUrl });
        setFormDataError({ ...formDataError, video: '' });
      }

    // enable button after video finishes uploading
    setIsDisabled(false);
  };

  const handleUrlChange = e => {
    setFormData({ ...formData, url: e.target.value });
  }

  // const validateInput = (value, stateName) => {
  //   if (!value) {
  //     setFormDataError((formDataError) => {
  //       if (stateName === 'logo') {
  //         return { ...formDataError, [stateName]: 'Please upload picture or logo' };
  //       } else {
  //         return { ...formDataError, [stateName]: 'Please choose the color' };
  //       }
  //     });
  //     return false;
  //   }

  //   setFormDataError((formDataError) => ({ ...formDataError, [stateName]: '' }));
  //   return true;
  // };

  const validateUrl = () => {
    let url;
  
    try {
      url = new URL(formData['url']);
    } catch (_) {
      return false;  
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  const handleCreateFutureListing = async () => {
    // const result = Object.keys(formData).map((key) => {
    //   return validateInput(formData[key], key);
    // });

    // const isInvalid = result.filter((r) => !r).length > 0;

    // if (!isValid) {
    //   return;
    // }

    if (formData['image'] || formData['video'] || formData['url']) {
      let data = {};

      if (formData['image']) {
        data.logo = formData.image;
      }

      if (formData['video']) {
        data.video = formData.video;
      }

      if (formData['url']) {
        if (!validateUrl()) {
          setFormDataError({ 
            ...formDataError,
            url: 'Please enter a valid URL'
          });
          return true;
        }
        data.loan_application_url = formData.url;
      }
      console.log(formData);
      let res = await dispatch(
        createFutureListing(data)
      );
  
      if (res.type === 'CREATE_FUTURE_LISITING_SUCCESS') {
        NotificationManager.success('Future Listing has been saved.', 'Success');
        history.push('/invite-buyers');
      } else {
        setError(res.data.message);
      }
    } else {
      setFormDataError({ 
        ...formDataError, 
        image: 'Please upload picture or logo', 
        video: 'Please upload video',
        url: 'Please enter application loan URL'
      });
      return true;
    }
  };

  return (
    <Layout>
      <div className="create-future-listing">
        <h3 className="title">
          {future_listing_state.logo ? 'Update' : 'Create'} Your Future Listing Branding Page
        </h3>
        <div className="container">
          <div className="white-card">
            <div className="upload-section-container">
              <div className="upload-logo-section">
                <ImageFileUploader previewUrl={imagePreviewUrl} uploadImage={uploadImage} />
                {formDataError.image && <p className="error-msg">{formDataError.image}</p>}
              </div>
              <div className="upload-video-section">
                <VideoFileUploader previewUrl={videoPreviewUrl} uploadVideo={uploadVideo} />
                {formDataError.video && <p className="error-msg">{formDataError.video}</p>}
              </div>
            </div>
            <div className="loan-application-section text-center">
              <h5 className="section-title loan-application-title">Loan Application URL</h5>
              <input type='url' value={formData.url || ''} onChange={handleUrlChange} />
              {formDataError.url && <p className="error-msg">{formDataError.url}</p>}
            </div>
            {/* <div className="color-pallete">
              <h5 className="section-title">Add color pallete</h5>
              <div className="color-picker-list">
                <ColorPicker updateColor={updateColor} name="color1" color={formData.color1} />
                <ColorPicker updateColor={updateColor} name="color2" color={formData.color2} />
                <ColorPicker updateColor={updateColor} name="color3" color={formData.color3} />
              </div>
              {(formDataError.color1 || formDataError.color2 || formDataError.color3) && (
                <p className="error-msg">{formDataError.color1}</p>
              )}
            </div> */}

            {error && (
              <div className="form__item text-center">
                <p className="error-msg">{error}</p>
              </div>
            )}

            <div className="text-center">
              <button className="primary-btn" onClick={handleCreateFutureListing} disabled={isDisabled}>Save</button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreateFutureListing;
