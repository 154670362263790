import { useState, useRef } from 'react';
import '../assets/styles/media-uploader.scss';
import uploadImg from '../assets/images/upload.svg';

const ImageFileUploader = ({ previewUrl, uploadVideo }) => {
  const ref = useRef();
  const [file, setFile] = useState(null);
  const [dragOver, setDragOver] = useState(false);
  const [errorNotification, setErrorNotification] = useState(null);

  /**
     Drag and Drop Event Handlers
  **/
  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    if (!dragOver) {
      setDragOver(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    let file = e.dataTransfer.files[0];

    // Validate file is of type Image/Video
    let fileType = file.type.split('/')[0];

    if (fileType === 'image' || fileType === 'video') {
      document.getElementById('upload-image-input').fileList = e.dataTransfer.files[0];
      setFile(file);
      setDragOver(false);
    } else {
      setFile(null);
      setErrorNotification('Not an image or video File');
      setDragOver(false);

      return setTimeout(() => {
        setErrorNotification(null);
      }, 3000);
    }
  };

  /**
      Handle Manually (File Input) Added Files
   **/
  const handleAddVideo = (e) => {
    e.preventDefault();
    let file = ref.current.files[0];

    if (!file) return;
    // Validate file is of type Video
    let fileType = ref.current.files[0].type.split('/')[0];

    if (fileType !== 'video') {
      setFile(null);
      setErrorNotification('Not a video File');
      // dragOverClass: ""
      return setTimeout(() => {
        setErrorNotification(null);
      }, 3000);
    }

    setFile(file);
    uploadVideo(file);
  };

  return (
    <>
      <h5 className="section-title">Upload video</h5>
      <div className="image-uploader-wrapper">
        <div className={dragOver ? 'display-box drag-over' : 'display-box'}>
          <div className="icon-text-box">
            <div className="upload-text">
              {previewUrl 
              ? (
                <div>
                  <video controls autoPlay muted src={previewUrl} height="160">
                  </video>
                </div>
              ) : (
                <div className="main-text">
                  <img src={uploadImg} alt="" />
                  <p className="drop">Upload video</p>
                  <p className="allowed-type">MP4, MKV, AVI up to 10MB</p>
                </div>
              )}
            </div>
            {errorNotification ? (
              <div className="error-notification">
                <p>{errorNotification}</p>
              </div>
            ) : null}
          </div>
          <div>
            <input
              type="file"
              ref={ref}
              id="upload-image-input"
              className="upload-image-input"
              accept="video/*"
              onDrop={(e) => handleDrop(e)}
              onDragEnter={(e) => handleDragEnter(e)}
              onDragOver={(e) => handleDragOver(e)}
              onDragLeave={(e) => handleDragLeave(e)}
              onChange={(e) => handleAddVideo(e)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageFileUploader;
