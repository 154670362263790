import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import rejectImg from '../../assets/images/reject.svg';
import { getAllUsers,deleteUser,updateUserStatus } from '../../actions/admin';
import moment from 'moment';

const Table = ({ headers, data, is_buyer = false }) => {
  const dispatch = useDispatch();
  
  const handleUpdateUserStatus = async (user, status) => {
    if(status === 'Waiting') {
      status = 1;
    } else if(status === 'Approved') {
      status = 2;
    } else if(status === 'Rejected') {
      status = 3;
    }
    
    await dispatch(updateUserStatus(user.email, status.toString()));
    dispatch(getAllUsers);
  };

  const handleUserDelete = async (user) => {
    var result = confirm(`Are you sure you want to delete ${user.first_name}?`);
    if (result) {
      const resp = await dispatch(deleteUser(user));

      if(resp.type === 'DELETE_USER_SUCCESS') {
        dispatch(getAllUsers());
      }
    }
  };

  return (
    <table>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.id}>
            <td>
              <Link to={is_buyer ? '/admin/buyer/' + item.id : '/admin/user/' + item.id}>
                {item.first_name} {item.last_name}
              </Link>
            </td>
            <td>{moment(item.date_joined).format('YYYY-MM-DD hh:mm:ss')}</td>
            <td>
                <select className="status" value={item.status} onChange={(e) => handleUpdateUserStatus(item, e.target.value)}>
                  <option value='Waiting'>Waiting</option>
                  <option value='Approved'>Approved</option>
                  <option value='Rejected'>Rejected</option>
                </select>
              {/* <div
                className={classNames({
                  status: true,
                  approved: item.status === 'Approved' || item.status === 'Letter of Intent Signed',
                  rejected: item.status === 'Rejected',
                })}
              >
                {item.status}
              </div> */}
            </td>
            {headers.includes('Action') && (
              <td className="actions user-actions">
                {(item.status === 'Letter of Intent Signed' && is_buyer) && (
                  <div className="button-group">
                    <span className="approve" onClick={() => handleUpdateUserStatus(item, 2)}>
                      Mark As Complete
                    </span>
                  </div>
                )}
                {(item.status === 'Pending Letter of Intent Signature' && is_buyer) && (
                  <div className="button-group">
                    <span>
                      No Action Available
                    </span>
                  </div>
                )}
                {(item.status === 'Waiting' && !is_buyer) && (
                  <div className="button-group">
                    <span className="approve" onClick={() => handleUpdateUserStatus(item, 2)}>
                      Approve
                    </span>
                    <div className="reject" onClick={() => handleUpdateUserStatus(item, 3)}>
                      <img src={rejectImg} alt="" />
                      <span>Reject</span>
                    </div>
                  </div>
                )}
                <div className="button-group">
                  <span className="delete" onClick={() => handleUserDelete(item)}>
                      Delete
                  </span>
                </div>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
