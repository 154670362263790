import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../../components/Layout';
import '../../assets/styles/profile-settings.scss';
import avatarImg from '../../assets/images/avatar.svg';
import { getUserDetail, updateUserStatus } from '../../actions/admin';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';

const UserDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const user = useSelector((state) => state.admin.user);

  const error = useSelector((state) => state.auth.error);

  useEffect(() => {
    dispatch(getUserDetail(params.id));
  }, [dispatch]);

  const handleUpdateUserStatus = async (user, status) => {
    await dispatch(updateUserStatus(user.email, status.toString()));
  };

  return (
    <Layout>
      <div className="admin-page user-detail">
        <h3 className="title">User Detail</h3>

        {user && (
          <div className="container">
            <div className="white-card padding-sm">
              <h5>User Avatar</h5>
              <div className="form__item">
                <div className="avatar-section">
                  <div className="main-image">
                    <img src={user.profile_pic ? user.profile_pic : avatarImg} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="white-card padding-sm">
              <h5>User Info</h5>
              <div className="form__item">
                <div className="form__item_inner">
                  <label>
                    Name : {user.first_name} {user.last_name}
                  </label>
                </div>
              </div>
              <div className="form__item">
                <div className="form__item_inner">
                  <label>Email : {user.email}</label>
                </div>
              </div>
              <div className="form__item">
                <div className="form__item_inner">
                  <label>User Type : {user.user_type}</label>
                </div>
              </div>
              <div className="form__item">
                <div className="form__item_inner">
                  <label>
                    Created At : {moment(user.date_joined).format('YYYY-MM-DD hh:mm:ss')}
                  </label>
                </div>
              </div>
              <div className="form__item">
                <div className="form__item_inner">
                  <label>License Number : {user.license_number}</label>
                </div>
              </div>
              <div className="form__item">
                <div className="form__item_inner">
                  <label>Phone Number : {user.phone_number}</label>
                </div>
              </div>
              {user.user_type === 'Loan Officer' && (
                <div className="form__item">
                  <div className="form__item_inner">
                    <label>Loan Officer Company : {user.loan_officer_company.name}</label>
                  </div>
                </div>
              )}
              {user.user_type === 'Realtor' && (
                <div className="form__item">
                  <div className="form__item_inner">
                    <label>Real Estate Company : {user.real_estate_company.name}</label>
                  </div>
                </div>
              )}
              <div className="form__item">
                <div className="form__item_inner">
                  <label>Status : {user.status}</label>
                </div>
              </div>
            </div>

            <div className="white-card padding-sm">
              <h5>Actions</h5>
              <div className="actions">
                {user.status === 'Waiting' && (
                  <>
                    <button
                      className="btn approve-btn"
                      onClick={() => handleUpdateUserStatus(user, 2)}
                    >
                      Approve
                    </button>
                    <button
                      className="btn reject-btn"
                      onClick={() => handleUpdateUserStatus(user, 3)}
                    >
                      Reject
                    </button>
                  </>
                )}
                <button className="btn default-btn" onClick={() => history.push('/admin/users')}>
                  Go Back
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default UserDetail;
