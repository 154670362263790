import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProspectiveClients } from '../actions/prospective_clients';
import Layout from '../components/Layout';
import ReactLoading from 'react-loading';
import '../assets/styles/prospective-clients.scss';
import moment from 'moment';

const ProspectiveClients = () => {
  const headers = ['Client', 'Date', 'Status'];
  
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.prospective_clients.loading);
  const prospective_clients = useSelector((state) => state.prospective_clients.data);

  useEffect(() => {
    dispatch(getProspectiveClients());
  }, [dispatch]);

  return (
    <Layout>
      <div className="prospective-clients">
        <div className="content">
          {loading
          ? <ReactLoading type={'spin'} color={'#1d60ad'} height={50} width={50} />
          :  <table>
              <thead>
                <tr>
                {headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
                </tr>
              </thead>
              <tbody>
              {prospective_clients.map((client) => (
                <tr key={client.uuid}>
                  <td>{client.recipient_email}</td>
                  <td>{moment(client.created_at).format('YYYY-MM-DD hh:mm:ss')}</td>
                  <td>{client.status}</td>
                </tr>
              ))}
              </tbody>
            </table>
          }
        </div>
      </div>
    </Layout>
  );
};

export default ProspectiveClients;
