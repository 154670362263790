import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Layout from '../../components/Layout';
import '../../assets/styles/buyer-future-listing.scss';
import { searchBuyerFutureListing } from '../../actions/future_listing';
import EmbedVideo from '../../components/EmbedVideo';
import ReactLoading from 'react-loading';

const FutureListing = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let { id } = useParams();

  /** example filter values 
  loan_purpose: 0,
  property_value: 200000,
  loan_amount: 200000,
  state: 'AZ',
  zipcode: 90011,
  property_type: 5,
  property_use: 1,
  credit_rating: 1,
  taxes: 100 
  */

  /*

  loan_purpose: '',
    property_value: '',
    loan_amount: '',
    state: '',
    zipcode: '',
    property_type: '',
    property_use: '',
    credit_rating: '',
    taxes: '',

    */
  
  const [filters, setFilters] = useState({
    property_value: '',
    loan_amount: '',
    state: '',
    zipcode: '',
    property_type: '',
    property_use: '',
    credit_rating: '',
    taxes: '',
    leadsource:'1000'
  });
  const[rate,setRate] = useState([]);
  const [isVideoScreen, setIsVideoScreen] = useState(true);
  
  const [title, settitle] = useState(true);
  const [error, setError] = useState('');
  const [emptyMsg, setEmptyMsg] = useState('Please enter search criteria to show available loans');
  const [formattedData, setFormattedData] = useState([]);
  const [percentage, setPercentage] = useState('');

  const buyerData = useSelector((state) => state.future_listing.buyerData);
  const mortechData = useSelector((state) => state.future_listing.mortech_response);
  const loading = useSelector((state) => state.future_listing.loading);
  const loan_url = useSelector((state) => state);


  useEffect(() => {
    dispatch(searchBuyerFutureListing(id));
  }, [dispatch, id]);

  

  // useEffect(() => {
  //   if (mortechData && mortechData.length) {
  //     let res = [];
  //     let rate = [];
  //     mortechData.map((m) => {
  //       let exist = false;
  //       for (let i = 0; i < res.length; i++) {
  //         if (res[i].product_name === m.quote.vendor_product_name) {
  //           res[i].children.push(m);
  //           exist = true;
  //         }
  //       }
  //       if (!exist && formattedData.length === 0) {
  //         res.push({
  //           product_name: m.quote.vendor_product_name,
  //           children: [m],
  //         });
  //       }
  //       if (formattedData.length > 0) {
  //         rate.push({
  //           rate: m.quote.quote_detail.rate,
  //         });
  //       }
  //     });
  //     if (formattedData.length > 0){
  //       setRate(rate);
  //     }
  //     else{
  //       setFormattedData(res);
  //     }
  //   }

  // }, [mortechData]);

  console.log(formattedData);
  console.log(rate);
  const handleApply = () => {
    history.push(
      '/buyer-signup?realtor=' +
        buyerData.user.first_name +
        ' ' +
        buyerData.user.last_name +
        '&realtor_id=' +
        id,
    );
  };

  const handletitle = () => {
    console.log(mortechData);
    window.location.replace(mortechData);
  };

  const gotoBuyerSignup = () => {
    history.push(
      '/buyer-signup?realtor=' +
        buyerData.user.first_name +
        ' ' +
        buyerData.user.last_name +
        '&realtor_id=' +
        id,
    );
  };

  const handleChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.type === 'select-one' ? e.target.value : parseInt(e.target.value),
    });
  };

  const handleSearch = async () => {
    if (!filters.loan_amount) {
      setError('Loan Amount is required');
    }
    setEmptyMsg('Sorry, no results matching your criteria were found.');
    setPercentage((filters.loan_amount / 100) * 1);
    await dispatch(searchBuyerFutureListing(id, filters));
    await dispatch(searchBuyerFutureListing(id, {
      ...filters,
      leadsource:'1001',
    }));
  };
  

  return (
    <Layout>
      <div className="buyer-future-listing">
        {title ? (
        <div className="section-header">
          {buyerData.logo ? <img src={buyerData.logo} /> : 'logo'}
          <h3 className="title" >
            {/* {' '} */}
            THE BUYERS ADVANTAGE PROGRAM ™
            {/* {buyerData.user ? buyerData.user.first_name + ' ' + buyerData.user.last_name : ''} */}
          </h3>
        </div>
        ):(
          <div className="section-header">
            {buyerData.logo ? <img src={buyerData.logo} /> : 'logo'}
            <h3 className="title" >
              {/* {' '} */}
              Compare Real-Time Rates
              {/* {buyerData.user ? buyerData.user.first_name + ' ' + buyerData.user.last_name : ''} */}
            </h3>
          </div>
        ) }
        {isVideoScreen ? (
          <>
            <div className="home-page">
              <h2 className="title">HOW IT WORKS </h2>
                <h3 className="subtitle">SEE HOW YOU CAN SAVE ON YOUR MORTGAGE COSTS!</h3>
              <div className="video-section">
              {buyerData.video ? (
                <video controls  loop  fluid={false} height="100%">
                  <source src={buyerData.video} type="video/mp4"></source>
                </video>
              ) : (
                <EmbedVideo embedId="ScMzIvxBSi4" />
              )}
              </div>
              <div className="actions">
                <button className="primary-btn" onClick={handletitle}>
                  Click to fill out a loan application
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="section-content">
              <div className="left-section">
                <div className="form__item">
                  <div className="form__item_inner">
                    <label htmlFor="loan_purpose">Loan Purpose</label>
                    <select
                      id="loan_purpose"
                      name="loan_purpose"
                      value={filters.loan_purpose}
                      onChange={handleChange}
                    >
                      <option default value="">
                        Loan Purpose
                      </option>
                      <option value="0">Purchase (default)</option>
                      <option value="1">Rate/term refinance</option>
                      <option value="2">Cash-out refinance</option>
                      {/* <option value="3">Home equity</option>
                      <option value="4">HELOC</option>
                      <option value="6">Streamline without credit without appraisal</option>
                      <option value="7">IRRRL without credit without appraisal</option>
                      <option value="12">Streamline with credit with appraisal</option>
                      <option value="14">Purchase – HUD REO</option>
                      <option value="15">Streamline with credit without appraisal</option>
                      <option value="16">Streamline without credit with appraisal</option>
                      <option value="17">IRRRL with credit without appraisal</option>
                      <option value="18">IRRRL without credit with appraisal</option> */}
                    </select>
                  </div>
                </div>
                <div className="form__item">
                  <div className="form__item_inner">
                    <label htmlFor="property_value">Property Value</label>
                    <input
                      type="number"
                      id="property_value"
                      name="property_value"
                      value={filters.property_value}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form__item">
                  <div className="form__item_inner">
                    <label htmlFor="loan_amount">Loan Amount</label>
                    <input
                      type="number"
                      id="loan_amount"
                      name="loan_amount"
                      value={filters.loan_amount}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form__row">
                  <div className="form__item">
                    <div className="form__item_inner">
                      <label htmlFor="state">State</label>
                      <select id="state" name="state" value={filters.state} onChange={handleChange}>
                        <option default value="CA">California</option>
                      </select>
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__item_inner">
                      <label htmlFor="zip_code">Zip code</label>
                      <input
                        type="number"
                        id="zip_code"
                        name="zipcode"
                        value={filters.zipcode}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="form__item">
                  <div className="form__item_inner">
                    <label htmlFor="property_type">Property Type</label>
                    <select
                      id="property_type"
                      name="property_type"
                      value={filters.property_type}
                      onChange={handleChange}
                    >
                      <option default value="">
                        Property Type
                      </option>
                      <option value="0">1 unit</option>
                      <option value="1">2 unit</option>
                      <option value="2">3 unit</option>
                      <option value="3">4 unit</option>
                      <option value="4">Co-op</option>
                      <option value="5">Manufactured home</option>
                      <option value="6">Warrantable condo &lt; 5 stories</option>
                      <option value="7">Warrantable condo 5-8 stories</option>
                      <option value="8">Warrantable condo &gt; 8 stories</option>
                      <option value="9">Non-warrantable condo &lt; 5 stories</option>
                      <option value="10">Non-warrantable condo 5-8 stories</option>
                      <option value="11">Non-warrantable condo &gt; 8 stories</option>
                      <option value="12">Condotel condo &lt; 5 stories</option>
                      <option value="13">Condotel condo 5-8 stories</option>
                      <option value="14">Condotel condo &gt; 8 stories</option>
                      <option value="15">Townhome</option>
                      <option value="20">Detached condo</option>
                    </select>
                  </div>
                </div> */}
                <div className="form__item">
                  <div className="form__item_inner">
                    <label htmlFor="property_use">Property Use</label>
                    <select
                      id="property_use"
                      name="property_use"
                      value={filters.property_use}
                      onChange={handleChange}
                    >
                      <option default value="">
                      Property Use
                      </option>
                      <option value="0">Owner occupied</option>
                      <option value="1">Non-owner occupied</option>
                      <option value="2">Second home</option>
                    </select>
                  </div>
                </div>
                <div className="form__item">
                  <div className="form__item_inner">
                    <label htmlFor="credit_rating">Credit Rating</label>
                    <input
                      type="number"
                      id="credit_rating"
                      name="credit_rating"
                      value={filters.credit_rating}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/* <div className="form__item">
                  <div className="form__item_inner">
                    <label htmlFor="taxes">Taxes</label>
                    <input
                      type="number"
                      id="taxes"
                      name="taxes"
                      value={filters.taxes}
                      onChange={handleChange}
                    />
                  </div>
                </div> */}
                <div className="action">
                  <button className="primary-btn" onClick={handleSearch}>
                    Try new Search
                  </button>
                </div>
              </div>
              <div className="right-section">
                {loading && <ReactLoading type={'spin'} color={'#1d60ad'} height={50} width={50} />}
                {!loading && (!mortechData || mortechData.length === 0) && (
                  <div className="no-result">{emptyMsg}</div>
                )}
                {!loading &&
                  formattedData.map((group, i) => (
                    <div className="group-item" key={'g' + i}>
                      <div className="group-title">{group.product_name}</div>
                      {group.children.map((item) => (
                        <table className="table-item" key={item.index}>
                          <tr className="table-item-header">
                            <th className="table-item-header-cell">
                              Rate with Future listing program
                            </th>
                            <th className="table-item-header-cell">Without program</th>
                            <th className="table-item-header-cell">Monthly Payment</th>
                            <th className="table-item-header-cell">Buyer’s Advantage Rebate</th>
                            <th className="table-item-header-cell">Buyer’s Advantage Interest Rate</th>
                            <th className="table-item-header-cell"/>
                          </tr>
                          <tbody className="table-item-content">
                          <tr>
                            <td className="table-item-content-cell">
                              {rate.length ? rate[i].rate + '%' : ''}
                            </td>
                            <td className="table-item-content-cell">
                              {item.quote ? item.quote.quote_detail.rate + '%' : ''}
                            </td>
                            <td className="table-item-content-cell">
                              {item.quote ? '$' + item.quote.quote_detail.piti : ''}
                            </td>
                            <td className="table-item-content-cell">
                              {'$' + Math.round(percentage) + ' Potential Future Listing Rebate' }
                            </td>
                            <td className="table-item-content-cell">
                              {rate.length ? rate[i].rate + '%' : ''}
                            </td>
                            <td className="table-item-content-cell">
                              <button className="primary-btn" onClick={handleApply}>
                                Apply
                              </button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      ))}
                    </div>
                  ))}
              </div>
            </div>
            <div className="action">
              <button className="send-loan" onClick={gotoBuyerSignup}>
                Send me a loan application, I'd like to get started
              </button>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default FutureListing;
