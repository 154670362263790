import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from '../../actions/admin';
import Layout from '../../components/Layout';
import UserTable from './UserTable';
import ReactLoading from 'react-loading';
import '../../assets/styles/admin.scss';

const headers = ['Username', 'Date of registration', 'Status', 'Action'];

const UserType = { REALTOR: 'realtor', LOANOFFICER: 'loan officer', BUYER: 'buyer' };
Object.freeze(UserType);

const UserStatus = { WAITING: 1, APPROVED: 2, REJECTED: 3 };
Object.freeze(UserStatus);

const UsersPage = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.admin.users);
  const loading = useSelector((state) => state.admin.loading);

  useEffect(() => {
    //dispatch(getAllUsers(UserType.LOANOFFICER, UserStatus.WAITING));
    dispatch(getAllUsers(UserType.BUYER, UserStatus.WAITING));
  }, [dispatch]);

  return (
    <Layout>
      <div className="admin-page">
        <div className="content">
          {loading ? (
            <ReactLoading type={'spin'} color={'#1d60ad'} height={50} width={50} />
          ) : (
            <UserTable headers={headers} data={users} is_buyer={true} />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default UsersPage;
