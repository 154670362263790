import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { buyerSignup, clearState } from '../../actions/auth';

const BuyerSignup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const buyerData = useSelector((state) => state.future_listing.buyerData);
  const systemError = useSelector((state) => state.auth.error);

  const [formData, setFormData] = useState({
    name: '',
    phone_number: '',
    email: '',
    realtor_name: params.get('realtor'),
    realtor_id: params.get('realtor_id'),
    property_address: '',
    city: '',
    state: 'CA',
    zipcode: '',
    prequalifed: false,
  });

  const [formDataError, setFormDataError] = useState({
    name: '',
    phone_number: '',
    email: '',
    realtor_name: '',
    realtor_id: '',
    property_address: '',
    city: '',
    state: '',
    zipcode: '',
    prequalifed: '',
    terms: '',
  });

  const [propertyChecks,SetPropertyChecks] = useState(false);
  const [TermChecks, setTermChecks] = useState(false);

  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);

  const handlepropertyChecks = () => {
    SetPropertyChecks(!propertyChecks);
    setFormData({ ...formData, property_address: "None" });
  };

  const handletermsChecks = () => {
    setTermChecks(!TermChecks);
  };


  const handleQualifyChecks = () => {
    setFormData({ ...formData, prequalifed: !formData.prequalifed });
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const validateInput = (value, stateName) => {
    if (stateName === 'email') {
      const emailValid = validateEmail(value);

      if (!emailValid) {
        setFormDataError((formDataError) => ({
          ...formDataError,
          [stateName]: 'Invalid e-mail address',
        }));
        return false;
      }
      setFormDataError((formDataError) => ({ ...formDataError, [stateName]: '' }));
      return true;
    }
    
    if (stateName === 'phone_number') {
      value = value.replace("+1", "")
      // return true;
    }

    if (stateName === 'property_address' && value === "None")  {
      return true;
    }

    if (value === '') {
      setFormDataError((formDataError) => {
        return { ...formDataError, [stateName]: 'This field should not be empty' };
      });
      
      return false;
    }

    setFormDataError((formDataError) => ({ ...formDataError, [stateName]: '' }));
    return true;
  };

  const onChange = (value, stateName) => {
    validateInput(value, stateName);
    setFormData({ ...formData, [stateName]: value });
  };

  const handleSignup = async () => {
    const result = Object.keys(formData).map((key) => {
      return validateInput(formData[key], key);
    });


    setFormDataError((formDataError) => ({
      ...formDataError
    }));

    setFormDataError((formDataError) => ({
      ...formDataError,
      terms: !TermChecks
        ? 'Please indicate that you have read and agree to the Terms and Conditions'
        : '',
    }));
    const isInvalid = result.filter((r) => !r).length > 0;

    if (isInvalid || !TermChecks) {
      return;
    }

    let resp = await dispatch(
      buyerSignup({
        name: formData.name,
        email: formData.email,
        phone_number: formData.phone_number,
        property_address: formData.property_address,
        city: formData.city,
        state: formData.state,
        zipcode: formData.zipcode,
        realtor_name: formData.realtor_name,
        realtor_id: formData.realtor_id,
        prequalifed: formData.prequalifed,
      }),
    );
    if (resp && resp.type == 'AUTH_BUYER_SIGNUP_SUCCESS') {
      window.location.href = `${process.env.REACT_APP_SERVER_URL}/esign/buyer-sign/${resp.payload.data.esign_token}/${resp.payload.data.buyer_id}`;
    }
  };

  // const goToLoginPage = () => {
  //   this.history.push('/login');
  // }

  return (
    <div className="auth__page">
      <div className="auth__page_card">
        {buyerData.logo ? <img src={buyerData.logo} width="100" height="100"/> : ''}
        <div className="auth__page_card_inner">
          <h3>Buyer Information</h3>
          <div className="form__item">
            <div className="form__item_inner">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={(e) => onChange(e.target.value, 'name')}
              />
            </div>
            {formDataError.name && <p className="error-msg">{formDataError.name}</p>}
          </div>
          <div className="form__item">
            <div className="form__item_inner">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={(e) => onChange(e.target.value, 'email')}
              />
            </div>
            {formDataError.email && <p className="error-msg">{formDataError.email}</p>}
          </div>
          <div className="form__item">
            <div className="form__item_inner">
              <label htmlFor="phone_number">Phone Number</label>
              <PhoneInput
                country={'us'}
                value={formData.phone_number}
                onlyCountries={['us']}
                onChange={(phone) => onChange(`+${phone}`, 'phone_number')}
                inputProps={{
                  name: 'phone_number',
                  required: true,
                }}
              />
            </div>
            {formDataError.phone_number && (
              <p className="error-msg">{formDataError.phone_number}</p>
            )}
          </div>
          <div className="form__item">
            <div className="form__item_inner">
              <label htmlFor="realtor_name">Realtor Name</label>
              <input
                type="text"
                id="realtor_name"
                name="realtor_name"
                value={formData.realtor_name}
                onChange={(e) => onChange(e.target.value, 'realtor_name')}
              />
            </div>
            {formDataError.realtor_name && (
              <p className="error-msg">{formDataError.realtor_name}</p>
            )}
          </div>
          <div className="CustomCheckBox">
            <input
              type="checkbox"
              checked={propertyChecks}
              id="property-checkbox"
              name="property-checkov"
              onChange={() => handlepropertyChecks()}
            />
            <label htmlFor="property-checkbox">
            I don’t know the property address yet
            </label>
            {/* {formDataError.property_address && <p className="error-msg">{formDataError.property_address}</p>} */}
          </div>
          <div className="form__item">
            <div className="form__item_inner">
              <label htmlFor="property_address">Property Address</label>
              <input
                type="text"
                id="property_address"
                name="property_address"
                value={formData.property_address}
                onChange={(e) => onChange(e.target.value, 'property_address')}
                readOnly={propertyChecks}
              />
            </div>
            {formDataError.property_address && (
              <p className="error-msg">{formDataError.property_address}</p>
            )}
          </div>
          <div className="form__item">
            <div className="form__item_inner">
              <label htmlFor="city">City</label>
              <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={(e) => onChange(e.target.value, 'city')}
              />
            </div>
            {formDataError.city && <p className="error-msg">{formDataError.city}</p>}
          </div>
          <div className="form__item">
            <div className="form__item_inner">
              <label htmlFor="state">State</label>
              <select
                id="state"
                name="state"
                value={formData.state}
                onChange={(e) => onChange(e.target.value, 'state')}
              >
                <option default value="CA">
                 California
                </option>
                {/* <option value="CA">California</option>        */}
              </select>
            </div>
            {formDataError.state && <p className="error-msg">{formDataError.state}</p>}
          </div>
          <div className="form__item">
            <div className="form__item_inner">
              <label htmlFor="zipcode">Zip code</label>
              <input
                type="text"
                id="zipcode"
                name="zipcode"
                value={formData.zipcode}
                onChange={(e) => onChange(e.target.value, 'zipcode')}
              />
            </div>
            {formDataError.zipcode && <p className="error-msg">{formDataError.zipcode}</p>}
          </div>
          <div className="CustomCheckBox">
            <input
              type="checkbox"
              checked={formData.prequalifed}
              id="qalify-checks"
              name="qalify-checks"
              onChange={() => handleQualifyChecks()}
            />
            <label htmlFor="qalify-checks">I/we need to be prequalified?</label>
            {formDataError.prequalifed && <p className="error-msg">{formDataError.prequalifed}</p>}
          </div>
          <div className="CustomCheckBox">
            <input
              type="checkbox"
              checked={TermChecks}
              id="terms-checks"
              name="terms-checks"
              onChange={() => handletermsChecks()}
            />
            <label htmlFor="terms-checks">I accept the <Link onClick={()=> window.open("/terms-and-conditions/buyer", "_blank")}>Terms and Conditions</Link></label>
            {formDataError.terms && <p className="error-msg">{formDataError.terms}</p>}
          </div>
          {/* <div className="CustomCheckBox">
            <input
              type="checkbox"
              checked={TermChecks}
              id="terms-checks"
              name="terms-checks"
              onChange={() => handletermsChecks()}
            />
            <label htmlFor="terms-conditions"> */}
              {/* <Link to="/terms-and-conditions/buyer">Terms and Conditions</Link> */}
              {/* I accept the <Link onClick={()=> window.open("/terms-and-conditions/buyer", "_blank")}>Terms and Conditions</Link>
            </label>
            {formDataError.terms && <p className="error-msg">{formDataError.terms}</p>}
          </div> */}
          {systemError && <p className="error-msg">{systemError}</p>}
          <button className="primary-btn" onClick={handleSignup}>
            Complete Signup
          </button>
        </div>
      </div>
    </div>
  );
};

export default BuyerSignup;
