import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../../components/Layout';
import '../../assets/styles/profile-settings.scss';
import avatarImg from '../../assets/images/avatar.svg';
import { getCompanyDetail, updateCompanyStatus } from '../../actions/company';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import UserTable from './UserTable';
const headers = ['Username', 'Date of registration', 'Status'];

const CompanyDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const company = useSelector((state) => state.company.company);
  const users = useSelector((state) => state.admin.users);
  const error = useSelector((state) => state.auth.error);


  useEffect(() => {
    dispatch(getCompanyDetail(params.id));
  }, [dispatch]);
   

  const handleUpdateCompanyStatus = async (company, status) => {
    status = company.company.id+','+status;
    await dispatch(updateCompanyStatus(company.company, status.toString()));
    dispatch(getCompanyDetail(params.id));
  };

  return (
    <Layout>
      <div className="admin-page user-detail">
        <h3 className="title">Company Detail</h3>

        <div className="container">
          <div className="white-card padding-sm">
            <h5>Company Logo</h5>
            <div className="form__item">
              <div className="avatar-section">
                <div className="main-image">
                  <img
                    src={
                      company.company && company.company.company_logo
                        ? company.company.company_logo
                        : avatarImg
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="white-card padding-sm">
            <h5>Company Info</h5>
            <div className="form__item">
              <div className="form__item_inner">
                <label>Name : {company.company ? company.company.name : ''}</label>
              </div>
            </div>
            <div className="form__item">
              <div className="form__item_inner">
                <label>Company Type : {company.company ? company.company.company_type : ''}</label>
              </div>
            </div>
            <div className="form__item">
              <div className="form__item_inner">
                <label>
                  Created At :{' '}
                  {company.company
                    ? moment(company.company.date_joined).format('YYYY-MM-DD hh:mm:ss')
                    : ''}
                </label>
              </div>
            </div>
            <div className="form__item">
              <div className="form__item_inner">
                <label>Status : {company.company ? company.company.status : ''}</label>
              </div>
            </div>
          </div>

          {company.users && company.users.length ? (
            <div className="white-card padding-sm">
              <h5>Users List</h5>
              <UserTable headers={headers} data={company.users} />
            </div>
          ) : (
            ''
          )}

          <div className="white-card padding-sm">
            <h5>Actions</h5>
            <div className="actions">
              {company.company && company.company.status === 'Waiting' && (
                <>
                  <button
                    className="btn approve-btn"
                    onClick={() => handleUpdateCompanyStatus(company, 2)}
                  >
                    Approve
                  </button>
                  <button
                    className="btn reject-btn"
                    onClick={() => handleUpdateCompanyStatus(company, 3)}
                  >
                    Reject
                  </button>
                </>
              )}
              <button className="btn default-btn" onClick={() => history.push('/admin/companies')}>
                Go Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CompanyDetail;
