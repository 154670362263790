import ProspectiveClientsApi from '../api/ProspectiveClientsApi';
import ActionTypes from '../constants/actionTypes';

export function getProspectiveClients() {
  return (dispatch) => {
    dispatch(request());
    return ProspectiveClientsApi.getProspectiveClients()
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.GET_PROSPECTIVE_CLIENTS.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.GET_PROSPECTIVE_CLIENTS.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.GET_PROSPECTIVE_CLIENTS.FAILURE, payload: error };
  }
}


export function Unsubscribe(id) {
  return (dispatch) => {
    dispatch(request());
    return ProspectiveClientsApi.Unsubscribe(id)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.GET_UNSUBSCRIBE.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.GET_UNSUBSCRIBE.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.GET_UNSUBSCRIBE.FAILURE, payload: error };
  }
}
