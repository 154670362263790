import FutureListingApi from '../api/FutureListingApi';
import ActionTypes from '../constants/actionTypes';

export function getFutureListing() {
  return (dispatch) => {
    dispatch(request());
    return FutureListingApi.getFutureListing()
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.GET_FUTURE_LISTING.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.GET_FUTURE_LISTING.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.GET_FUTURE_LISTING.FAILURE, payload: error };
  }
}

export function getBuyerFutureListing(id) {
  return (dispatch) => {
    dispatch(request());
    return FutureListingApi.getBuyerFutureListing(id)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.GET_BUYER_FUTURE_LISTING.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.GET_BUYER_FUTURE_LISTING.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.GET_BUYER_FUTURE_LISTING.FAILURE, payload: error };
  }
}

export function searchBuyerFutureListing(id, filters) {
  return (dispatch) => {
    dispatch(request());
    return FutureListingApi.searchBuyerFutureListing(id, filters)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.GET_BUYER_FUTURE_LISTING.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.GET_BUYER_FUTURE_LISTING.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.GET_BUYER_FUTURE_LISTING.FAILURE, payload: error };
  }
}

export function createFutureListing(data) {
  return (dispatch) => {
    dispatch(request());
    return FutureListingApi.create(data)
      .then((resp) => {
        console.log('resp', resp);
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        return dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.CREATE_FUTURE_LISITING.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.CREATE_FUTURE_LISITING.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.CREATE_FUTURE_LISITING.FAILURE, payload: error };
  }
}
