import React from 'react';
import { useHistory } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import '../../assets/styles/modal.scss';

const AddUserSuccess = () => {
  const history = useHistory();

  return (
    <div className="custom-modal">
      <div className="modal-card">
        <h3>User is added successfully!</h3>
        <div className="actions">
          <button className="primary-btn" onClick={() => history.push('/admin/users')}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddUserSuccess;
