import { Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';

import Login from './pages/auth/Login';
import SignUp from './pages/auth/SignUp';
import BuyerSignup from './pages/auth/BuyerSignup';

import ForgotPasswordPage from './pages/auth/ForgotPassword';
import CreatePasswordPage from './pages/auth/CreatePassword';
import ThanksPage from './pages/auth/Thanks';
import Home from './pages/Home';
import CreateFutureListing from './pages/CreateFutureListing';
import ProfileSettings from './pages/ProfileSettings';
import InviteBuyers from './pages/InviteBuyers';
import ProspectiveClients from './pages/ProspectiveClients';

// Buyer
import BuyerFutureListing from './pages/buyer/FutureListing';

// Admin Pages

import AdminUsers from './pages/admin/UsersPage';
import AdminCompanies from './pages/admin/CompaniesPage';
import AdminBuyers from './pages/admin/BuyersPage';
import AdminUserDetail from './pages/admin/UserDetailPage';
import AdminCompanyDetail from './pages/admin/CompanyDetailPage';
import AdminBuyerDetail from './pages/admin/BuyerDetailPage';
import AddRealtor from './pages/admin/AddRealtor';
import AddLoanOfficer from './pages/admin/AddLoanOfficer';

import BuyerTerms from './pages/terms/Buyer';
import RealtorTerms from './pages/terms/Realtor';
import LoanOfficerTerms from './pages/terms/LoanOfficer';

import SuccessfulUnsubscription from './pages/SuccessfulUnsubscription';

import './assets/styles/global.scss';

import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import configureStore from './store';
const store = configureStore({});

function PrivateRoute({ component: Component, ...rest }) {
  const authed = useSelector((state) => state.auth.authenticated);

  return (
    <Route
      {...rest}
      render={(props) => (authed ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
}

function PublicRoute({ component: Component, ...rest }) {
  const authed = useSelector((state) => state.auth.authenticated);
  const user = useSelector((state) => state.auth.user);
  let redirectUrl = '/home';
  if (user.user_type === 'Admin') {
    redirectUrl = '/admin/users';
  } else if (user.user_type === 'Buyer') {
    redirectUrl = '/buyer/future-listing';
  }
  return (
    <Route
      {...rest}
      render={(props) => (authed ? <Redirect to={redirectUrl} /> : <Component {...props} />)}
    />
  );
}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <PublicRoute exact path="/" component={Login} />
          <PublicRoute path="/signup" component={SignUp} />
          <PublicRoute path="/buyer-signup" component={BuyerSignup} />
          <PublicRoute path="/thanks" component={ThanksPage} />
          <PublicRoute path="/create-password" component={CreatePasswordPage} />
          <PublicRoute path="/forgot-password" component={ForgotPasswordPage} />

          <PrivateRoute path="/home" component={Home} />
          <PrivateRoute path="/create-future-listing" component={CreateFutureListing} />
          <PrivateRoute path="/profile" component={ProfileSettings} />
          <PrivateRoute path="/invite-buyers" component={InviteBuyers} />
          <PrivateRoute path="/prospective-clients" component={ProspectiveClients} />

          <Route path="/buyer/future-listing/:id" component={BuyerFutureListing} />
          <PrivateRoute path="/admin/users" component={AdminUsers} />
          <PrivateRoute path="/admin/buyers" component={AdminBuyers} />
          <PrivateRoute path="/admin/companies" component={AdminCompanies} />
          <PrivateRoute path="/admin/user/:id" component={AdminUserDetail} />
          <PrivateRoute path="/admin/company/:id" component={AdminCompanyDetail} />
          <PrivateRoute path="/admin/buyer/:id" component={AdminBuyerDetail} />
          <PrivateRoute path="/admin/add-realtor" component={AddRealtor} />
          <PrivateRoute path="/admin/add-loan-officer" component={AddLoanOfficer} />

          <Route path="/terms-and-conditions/buyer" component={BuyerTerms} />
          <Route path="/terms-and-conditions/realtor" component={RealtorTerms} />
          <Route path="/terms-and-conditions/loan-officer" component={LoanOfficerTerms} />

          <Route path="/unsubscribe/:id" component={SuccessfulUnsubscription} />
        </div>
        <NotificationContainer />
      </Router>
    </Provider>
  );
}

export default App;
