import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createPassword } from '../../actions/auth';
import '../../assets/styles/auth.scss';

const CreatePassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [formData, setFormData] = useState({
    password: '',
    confirm_password: '',
  });

  const [formDataError, setFormDataError] = useState({
    password: '',
    confirm_password: '',
  });

  const systemError = useSelector((state) => state.auth.error);

  const validateInput = (value, stateName) => {
    if (!value) {
      setFormDataError((formDataError) => ({
        ...formDataError,
        [stateName]: 'This field should not be empty',
      }));
      return false;
    }

    switch (stateName) {
      case 'password':
        if (formData['confirm_password'] !== value) {
          setFormDataError((formDataError) => ({
            ...formDataError,
            confirm_password: `Passwords don't match.`,
          }));
          return false;
        } else {
          setFormDataError((formDataError) => ({
            ...formDataError,
            confirm_password: '',
            password: '',
          }));
          return true;
        }
      case 'confirm_password':
        if (formData['password'] !== value) {
          setFormDataError((formDataError) => ({
            ...formDataError,
            confirm_password: `Passwords don't match.`,
          }));
          return false;
        } else {
          setFormDataError((formDataError) => ({
            ...formDataError,
            confirm_password: '',
            password: '',
          }));
          return true;
        }
      default:
        setFormDataError((formDataError) => ({ ...formDataError, [stateName]: '' }));
        return true;
    }
  };

  const onChange = (value, stateName) => {
    validateInput(value, stateName);
    setFormData({ ...formData, [stateName]: value });
  };

  const handleSubmit = async () => {
    const result = Object.keys(formData).map((key) => {
      return validateInput(formData[key], key);
    });

    const isInvalid = result.filter((r) => !r).length > 0;

    if (isInvalid) {
      return;
    }

    let resp = await dispatch(
      createPassword(history.location.pathname, {
        password: formData.password,
        confirm_password: formData.confirm_password,
      }),
    );

    if (resp && resp.type == 'AUTH_CREATE_PASSWORD_SUCCESS') {
      history.push('/');
    }
  };

  return (
    <div className="auth__page">
      <div className="auth__page_card">
        <h3 className="title">Create Password</h3>
        <div className="form__item">
          <div className="form__item_inner">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              onChange={(e) => onChange(e.target.value, 'password')}
            />
          </div>
          {formDataError.password && <p className="error-msg">{formDataError.password}</p>}
        </div>
        <div className="form__item">
          <div className="form__item_inner">
            <label htmlFor="confirm_password">Confirm Password</label>
            <input
              type="password"
              id="password-confirmation"
              name="confirm_password"
              onChange={(e) => onChange(e.target.value, 'confirm_password')}
            />
          </div>
          {formDataError.confirm_password && (
            <p className="error-msg">{formDataError.confirm_password}</p>
          )}
        </div>
        {systemError && <p className="error-msg">{systemError}</p>}
        <button className="primary-btn" onClick={() => handleSubmit()}>
          Set Password
        </button>
      </div>
    </div>
  );
};

export default CreatePassword;
