import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tabs from './Tabs';
import CompanyTable from './CompanyTable';
import { getAllCompanies } from '../../actions/company';
import ReactLoading from 'react-loading';

const companyListTabs = [
  { id: 'real estate', title: 'Real estate agent companies', selected: true },
  { id: 'loan officer', title: 'Loan Officer companies', selected: false },
];

const QueueListing = () => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTabId] = useState('real estate');

  const handleSelectedTab = (id) => {
    setSelectedTabId(id);
  };

  const companies = useSelector((state) => state.company.companies);
  const loading = useSelector((state) => state.company.loading);

  useEffect(() => {
    dispatch(getAllCompanies());
  }, [dispatch]);

  return (
    <div className="queue-listing">
      <div className="section-header">
        <Tabs originalTabs={companyListTabs} handleSelectedTab={handleSelectedTab} />
      </div>
      <div className="content">
        {loading ? (
          <ReactLoading type={'spin'} color={'#1d60ad'} height={50} width={50} />
        ) : (
          <CompanyTable
            data={companies.filter((companyItem) => {
              if (selectedTab === 'real estate') {
                return companyItem.company_type === 'Real estate agent company';
              } else if (selectedTab === 'loan officer') {
                return companyItem.company_type === 'loan officer company';
              }
              return false;
            })}
          />
        )}
      </div>
    </div>
  );
};

export default QueueListing;
