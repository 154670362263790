import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAllUsers } from '../../actions/admin';
import Tabs from './Tabs';
import UserTable from './UserTable';
import ReactLoading from 'react-loading';

const userListTabs = [
  { id: 'realtor', title: 'Realtor user', selected: true },
  { id: 'loan officer', title: 'Loan Officer', selected: false },
];

const headers = ['Username', 'Date of registration', 'Status', 'Action'];

const UserType = { REALTOR: 'realtor', LOANOFFICER: 'loan officer' };
Object.freeze(UserType);

const UserStatus = { WAITING: 1, APPROVED: 2, REJECTED: 3 };
Object.freeze(UserStatus);

const UserList = () => {
  const [selectedTab, setSelectedTabId] = useState('realtor');

  const handleSelectedTab = (id) => {
    setSelectedTabId(id);
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const users = useSelector((state) => state.admin.users);
  const loading = useSelector((state) => state.admin.loading);

  useEffect(() => {
    //dispatch(getAllUsers(UserType.LOANOFFICER, UserStatus.WAITING));
    dispatch(getAllUsers());
    // if(selectedTab === 'realtor') {
    //   dispatch(getAllUsers('realtor'));
    // } else {
    //   dispatch(getAllUsers('loan officer'));
    // }
  }, [dispatch]);

  const redirectToAddUser = () => {
    let path = 'add-loan-officer';

    if (selectedTab === 'realtor') {
      path = 'add-realtor';
    }

    history.push(path);
  }

  return (
    <div className="user-list">
      <div className="section-header">
        <Tabs originalTabs={userListTabs} handleSelectedTab={handleSelectedTab} />
      </div>
      <div className="content">
        <button className='primary-btn add-user-btn' onClick={redirectToAddUser}>Add User</button>
        {loading ? (
          <ReactLoading type={'spin'} color={'#1d60ad'} height={50} width={50} />
        ) : (
          <UserTable
            headers={headers}
            data={users.filter((userItem) => {
              if (selectedTab === 'realtor') {
                return userItem.user_type === 'Realtor';
              } else if (selectedTab === 'loan officer') {
                return userItem.user_type === 'Loan Officer';
              }
              return false;
            })}
          />
        )}
      </div>
    </div>
  );
};

export default UserList;
