import axios from 'axios';
import BaseApi from './BaseApi';

class CompanyApi extends BaseApi {
  getAllCompanies() {
    return axios.get(this.REACT_APP_SERVER_URL + '/companies');
  }

  addCompany(name, company_type, brokerage_firm_dre_license_number) {
    return axios.post(this.REACT_APP_SERVER_URL + '/company', {
      name,
      company_type,
      brokerage_firm_dre_license_number,
    });
  }

  getCompanyDetail(id) {
    return axios.get(this.REACT_APP_SERVER_URL + '/company-detail/' + id, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  updateCompanyStatus(id, status) {
    let values = status.split(',')
    if (values.length > 1){
      id = status.split(',')[0];
      status = status.split(',')[1];
    }
    return axios.post(
      this.REACT_APP_SERVER_URL + '/update-company-status',
      { id, status },
      {
        headers: { Authorization: `Bearer ${this.getToken()}` },
      },
    );
  }

  deleteCompany(id) {
    return axios.delete(this.REACT_APP_SERVER_URL + '/company/' + id, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }
}

export default new CompanyApi();
