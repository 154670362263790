import ActionTypes from '../constants/actionTypes';

const initialState = {
  data: [],
  loading: false,
  error: '',
};

export default function company(state = initialState, action) {
  let { payload } = action;

  switch (action.type) {
    case ActionTypes.GET_PROSPECTIVE_CLIENTS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_PROSPECTIVE_CLIENTS.SUCCESS:
      return {
        ...state,
        data: payload ? payload : initialState.data,
        loading: false,
      };
    case ActionTypes.GET_PROSPECTIVE_CLIENTS.FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
